import {
  defaultHeaderImg,
  emptyHeaderImg,
  headerSize,
  rightIcon,
  rightIconSize,
  toLogin,
} from './me.variable';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import {View} from 'react-native';
import LazyImage from '@basicComponents/image';
import Text from '@basicComponents/text';
import {IUserInfo} from '@services/global.service';
import {VipRenderType} from '@businessComponents/vip';
import i18n from '@i18n';

const {flex, padding, font, margin} = theme;
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';

interface MeUserProps {
  login?: boolean;
  user?: IUserInfo;
  currentVipOption: VipRenderType;
  onUser?: () => void;
  showNoMenu?: boolean;
}

const MeUser: React.FC<MeUserProps> = ({
  login,
  user,
  currentVipOption,
  onUser,
  showNoMenu,
}) => {
  return (
    <NativeTouchableOpacity onPress={onUser}>
      <View style={[flex.row, margin.btmm, flex.between, flex.centerByCol]}>
        <View style={[flex.row]}>
          <View style={[margin.rightl]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={globalStore.token ? defaultHeaderImg : emptyHeaderImg}
              width={(globalStore.screenWidth * headerSize) / 375}
              height={(globalStore.screenWidth * headerSize) / 375}
            />
          </View>

          {login ? (
            <View style={[flex.col, flex.centerByRow]}>
              <View style={[flex.row]}>
                <Text blod style={[font.second, font.fm, margin.rights]}>
                  {user?.userPhone}
                </Text>
                {currentVipOption.smallFn(20)}
              </View>
              <View style={[flex.row, margin.tops, flex.centerByCol]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/me/mobile.webp')}
                  width={14}
                  height={14}
                />
                <Text
                  style={[margin.leftxxs, font.second]}
                  fontSize={theme.fontSize.s}>
                  {user?.userPhone}
                </Text>
                <Text
                  style={[margin.leftl, font.second]}
                  fontSize={theme.fontSize.s}>
                  {i18n.t('me.user.userId')}: {+(user?.userId || 0) + 100000}
                </Text>
              </View>
            </View>
          ) : (
            <View style={[flex.col, flex.centerByRow, flex.alignStart]}>
              <Text style={[font.fs, font.accent, padding.btmxxs]}>
                {i18n.t('me.user.moreExciting')}
              </Text>
              {/* TODO 这里会引起报错,button不能是另一个botton的子元素 */}
              <Button
                title={i18n.t('me.user.loginUpper')}
                type="linear-primary"
                titleBold
                size="small"
                onPress={toLogin}
              />
            </View>
          )}
        </View>
        {showNoMenu && (
          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={rightIconSize}
            height={rightIconSize}
          />
        )}
      </View>
    </NativeTouchableOpacity>
  );
};

export default MeUser;
