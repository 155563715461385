import React, {useMemo} from 'react';
import {KeralaListItem} from '../../home.type';
import Text from '@basicComponents/text';
import {View, StyleSheet} from 'react-native';
import theme from '@/style';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo, toPriceStr} from '@/utils';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import globalStore from '@/services/global.state';

const HomeKeralaCard = ({
  item,
  marginRight,
  cardWidth,
  imageHeight,
}: {
  item: KeralaListItem;
  marginRight: boolean;
  cardWidth: number;
  imageHeight: number;
}) => {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const remain = useMemo(() => {
    const seconds = dayjs(item.drawDate).diff(dayjs(), 'second');
    if (seconds < 0) {
      return {hours: 0, minutes: 0, closed: true};
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60) % 60;
    return {hours, minutes, closed: false};
  }, [item]);
  const lotteryName = useMemo(
    () =>
      (item.lotteryType?.indexOf('BUMPER') > -1 ? 'festival' : 'weekly') +
      ' lottery',
    [item.lotteryType],
  );
  return (
    <NativeTouchableOpacity
      key={item.issueNo}
      style={[
        {
          width: cardWidth,
        },
        marginRight ? theme.margin.rights : null,
        theme.flex.col,
        theme.borderRadius.s,
        theme.overflow.hidden,
        theme.background.white,
      ]}
      onPress={() => {
        if (remain.closed) {
          globalStore.globalWaringTotal(
            i18n.t('home.tip.closed', {name: `${lotteryName} ${item.issueNo}`}),
          );
          return;
        }
        goTo('GameWebView', {
          type: 'kerala',
          params: `data=${JSON.stringify(item)}`,
        });
      }}>
      <LazyImageBackground
        height={imageHeight}
        width={cardWidth}
        imageUrl={item.backImg}
        style={[
          theme.flex.row,
          theme.flex.alignStart,
          theme.flex.between,
          theme.padding.s,
        ]}>
        <Text
          fontSize={8}
          blod
          style={[styles.lotteryType, theme.padding.lrxxs, theme.font.white]}>
          {lotteryName}
        </Text>
        <View style={[theme.flex.col, theme.flex.alignEnd]}>
          <Text fontSize={9} blod style={[theme.font.white]}>
            {i18n.t('home.kerala.no')}
          </Text>
          <Text
            fontSize={9}
            blod
            style={[
              theme.font.white,
              globalStore.isWeb ? theme.margin.topxxs : {},
            ]}>
            {item.issueNo}
          </Text>
        </View>
      </LazyImageBackground>
      <View
        style={[
          theme.padding.s,
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
        ]}>
        <View style={[theme.flex.col]}>
          <Text main fontFamily="fontInterBold">
            {toPriceStr(item.lotteryPrice, {currency: globalStore.currency})}
          </Text>
        </View>
        <View style={[theme.flex.col, theme.flex.alignEnd]}>
          <Text fontSize={9} accent>
            {weekDays[new Date(item.drawDate).getDay()]} {item.drawTime}
          </Text>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={require('@assets/icons/home/hourglass.webp')}
              width={9}
              height={9}
            />
            <Text fontSize={9} accent style={[theme.margin.leftxxs]}>
              {remain.hours} hrs {remain.minutes} mins
            </Text>
          </View>
        </View>
      </View>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  lotteryType: {
    borderRadius: 2,
    paddingVertical: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
  },
});

export default HomeKeralaCard;
