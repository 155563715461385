import Text from '@basicComponents/text';
import React from 'react';
import {View, StyleSheet, ImageBackground, Image} from 'react-native';
import theme from '@style';
import TouchableOpacity, {
  NativeTouchableOpacity,
} from '@basicComponents/touchable-opacity';
import i18n from '@/i18n';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {goTo} from '@/utils';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {combineLatest} from 'rxjs';
import {postUserInfo} from '@services/global.service';
const defaultHeaderImg = require('@components/assets/icons/default-header.webp');

const HomeHeader = (props: {setMenuOpen: (bool: boolean) => void}) => {
  const {setMenuOpen} = props;
  const [showLogin, setShowLogin] = React.useState(false);
  const [showUser, setShowUser] = React.useState(false);
  const [userName, setUserName] = React.useState(false);
  const [amount, setAmount] = React.useState<number>(0);
  const styles = StyleSheet.create({
    bellTipIcon: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
  });
  React.useEffect(() => {
    const sub = combineLatest([
      globalStore.tokenSubject,
      globalStore.globalLoading,
    ]).subscribe(([t, l]) => {
      // 没有token且没有加载时,显示login按钮
      setShowLogin(!t && !l);
      setShowUser(!!t);
      if (t) {
        postUserInfo().then(res => {
          setUserName(res.userName || res.userPhone);
        });
      }
    });
    const amountSub = globalStore.amountChanged.subscribe(res => {
      if (res.current) {
        setAmount(res.current);
      }
    });
    return () => {
      sub.unsubscribe();
      amountSub.unsubscribe();
    };
  }, []);
  return (
    <DetailNavTitle
      containerStyle={[
        theme.flex.row,
        theme.flex.centerByCol,
        {
          paddingTop: theme.paddingSize.zorro,
          paddingBottom: theme.paddingSize.zorro,
        },
      ]}
      hideServer
      leftNode={
        <TouchableOpacity
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={() => setMenuOpen(true)}>
          <Image
            style={[theme.icon.l, theme.margin.rightxxs]}
            source={require('@assets/icons/menu.webp')}
          />
          <Text size="medium" fontFamily="fontInterBold" accent>
            {i18n.t('home.label.menu')}
          </Text>
        </TouchableOpacity>
      }
      hideAmount
      rightNode={
        <View style={[theme.flex.centerByCol, theme.flex.row]}>
          {showLogin && (
            <View
              style={[
                theme.flex.centerByCol,
                theme.flex.row,
                {
                  marginLeft: theme.paddingSize.xl + theme.paddingSize.m,
                },
              ]}>
              <Button
                size="small"
                type="linear-primary"
                titleBold={true}
                title="LOGIN"
                onPress={() => {
                  goTo('Login');
                }}
              />
            </View>
          )}
          {showUser && false && (
            <NativeTouchableOpacity
              onPress={() => {
                globalStore.globalTotal.next({
                  type: 'warning',
                  message: i18n.t('warning.unopen'),
                });
              }}
              style={[
                theme.padding.s,
                theme.position.rel,
                {
                  marginRight: -theme.paddingSize.s,
                },
              ]}>
              <Image
                style={[theme.icon.xl]}
                source={require('@assets/icons/bell.webp')}
              />
              <ImageBackground
                style={[theme.icon.m, styles.bellTipIcon]}
                source={require('@components/assets/icons/tip-dot.webp')}
              />
            </NativeTouchableOpacity>
          )}
          {showUser && (
            <TouchableOpacity
              onPress={() => goTo('Me')}
              style={[
                theme.flex.centerByCol,
                theme.flex.row,
                {
                  marginLeft: theme.paddingSize.xl + theme.paddingSize.m,
                },
              ]}>
              <View style={[theme.flex.col]}>
                <Text
                  accent
                  style={[{marginBottom: -theme.paddingSize.xxs / 2}]}>
                  {userName}
                </Text>
                <Text main textAlign="right" size="medium" blod>
                  {globalStore.currency}
                  {amount}
                </Text>
              </View>
              <Image
                source={defaultHeaderImg}
                style={[theme.icon.xxl, theme.margin.lefts]}
              />
            </TouchableOpacity>
          )}
        </View>
      }>
      <View style={[theme.flex.flex1]} />
    </DetailNavTitle>
  );
};

export default HomeHeader;
