import React from 'react';
import NavTitle from '@basicComponents/nav-title';
import i18n from '@i18n';
import {View, Image} from 'react-native';
import theme from '@style';
import {goBack, goTo} from '@utils';
import {ScrollView} from 'react-native-gesture-handler';
import PhoneInput from './components/phone-input';
import CodeInput from './components/code-input';
import {inputProps, styles} from './login.style';
import {CheckBox, Input} from '@rneui/themed';
import Text from '@basicComponents/text';
import AccountTip from './components/account-tip';
import Button from '@basicComponents/button';
import {userLogin} from './login.service';
import globalStore from '@/services/global.state';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {setScratchAuth} from '@/services/global.service';

const SingUp = (props: NavigatorScreenProps) => {
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPage =
    ((props.route.params as BasicObject)?.sucessPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPageParams =
    ((props.route.params as BasicObject)?.sucessPageParams as BasicObject) ||
    null;
  const [userPhone, setUserPhone] = React.useState('');
  const [OTPCode, setOTPCode] = React.useState('');
  const [invitaCode, setInvitaCode] = React.useState('');
  const [is18, setIs18] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [blured, setBlured] = React.useState(true);
  const [userPhoneCode, setUserPhoneCode] = React.useState('+91');
  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.white]}>
      <NavTitle onClose={goBack} title={i18n.t('login.label.sign')} />
      <ScrollView style={[{padding: theme.paddingSize.l * 2}]}>
        <PhoneInput
          userPhoneCode={userPhoneCode}
          setUserPhoneCode={setUserPhoneCode}
          userPhone={userPhone}
          setUserPhone={setUserPhone}
        />
        <View style={styles.interval} />
        <CodeInput
          setValueOrCode={setOTPCode}
          swicthIndex={0}
          userPhone={userPhone}
          OTPCode={OTPCode}
        />
        <View style={styles.interval} />
        <View
          style={[
            styles.inputBox,
            blured ? styles.greyBorder : styles.deepBorder,
          ]}>
          <Image
            style={theme.icon.s}
            source={require('@assets/icons/login/invitation-code.webp')}
          />
          <View style={theme.flex.flex1}>
            <Input
              {...inputProps}
              onFocus={() => setBlured(false)}
              onBlur={() => setBlured(true)}
              value={invitaCode}
              onChangeText={setInvitaCode}
              maxLength={6}
              placeholder={i18n.t('login.tip.referral-code')}
            />
          </View>
        </View>
        {[
          {checked: is18, onPress: setIs18, label: 'login.tip.page18'},
          {checked: agree, onPress: setAgree, label: 'login.tip.notify'},
        ].map((v, i) => (
          <CheckBox
            key={i}
            containerStyle={{
              padding: theme.paddingSize.zorro,
              marginTop: theme.paddingSize.m * 2,
              marginLeft: theme.paddingSize.zorro,
              marginRight: theme.paddingSize.zorro,
            }}
            checked={v.checked}
            onPress={() => v.onPress(!v.checked)}
            checkedIcon={
              <Image
                style={theme.icon.m}
                source={require('@components/assets/icons/checked.webp')}
              />
            }
            uncheckedIcon={
              <Image
                style={theme.icon.m}
                source={require('@components/assets/icons/unchecked.webp')}
              />
            }
            title={
              <Text
                style={theme.padding.lefts}
                fontSize={theme.fontSize.m}
                accent>
                {i18n.t(v.label)}
              </Text>
            }
          />
        ))}
        <View
          style={{
            marginVertical: theme.paddingSize.m * 2,
          }}>
          <Button
            onPress={() => {
              globalStore.globalLoading.next(true);
              userLogin(userPhone, OTPCode, invitaCode)
                .then(token => {
                  globalStore.triggerAdjustEvent('account_register');
                  globalStore.token = token;
                  const data: BasicObject = {fromLogin: true};
                  sucessPage && (data.sucessPage = sucessPage);
                  sucessPageParams &&
                    (data.sucessPageParams = sucessPageParams);
                  setScratchAuth(() => goTo('SetPassword', data));
                })
                .finally(() => globalStore.globalLoading.next(false));
            }}
            disabled={
              userPhone.length !== 10 || OTPCode.length !== 6 || !is18 || !agree
            }>
            <Text color={theme.basicColor.white} size="large" fontWeight="700">
              {i18n.t('login.label.next')}
            </Text>
          </Button>
        </View>
        <AccountTip
          tip="login.tip.has-account"
          linkTip="login.tip.sign-in"
          onPressLink={() => goTo('Login')}
        />
      </ScrollView>
    </View>
  );
};

export default SingUp;
