export const basicColor = {
  /** 主题色 */
  primary: '#9800F5',
  red: 'red',
  white: '#fff',
  dark: '#000',
  transparent: '#0000',
};

export const fontColor = {
  main: '#000',
  second: '#31373D',
  accent: '#5F6975',
  secAccent: '#9FA5AC',
};

export const backgroundColor = {
  /** 蓝 */
  main: '#9800F5',
  /** 橙 */
  second: '#F15802',
  /** 红 */
  accent: '#E20000',
  /** 绿 */
  secAccent: '#009919',
  grey: '#D8E0EA',
  /** 浅灰色,用于图片加载前的颜色 */
  palegrey: '#E8EBEE',
  lightGrey: '#EFF1F7',
};

export const fontSize = {
  xs: 10,
  s: 12,
  m: 14,
  l: 16,
  xl: 20,
};

export const paddingSize = {
  zorro: 0,
  xxs: 4,
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
  xl: 14,
  xxl: 16,
};

export const borderRadiusSize = {
  xs: 4,
  s: 6,
  m: 8,
  l: 10,
  xl: 12,
};

export const iconSize = {
  xxs: 10,
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 28,
  xxl: 32,
};

export const imageSize = {
  xs: 36,
  s: 48,
  m: 64,
};
