import React from 'react';
import {StyleProp, View, ViewStyle, Image} from 'react-native';
import Text from '@basicComponents/text';
import {Button, Input} from '@rneui/themed';
import theme from '@/style';
import globalStore from '@/services/global.state';
import {Shadow} from 'react-native-shadow-2';

const inputProps = {
  containerStyle: {
    paddingHorizontal: theme.paddingSize.m,
  },
  inputContainerStyle: {
    borderBottomWidth: 0,
  },
  inputStyle: {
    minHeight: 44,
    maxHeight: 44,
    height: 44,
    padding: 0,
    ...theme.font.main,
    ...theme.font.fm,
  },
  errorStyle: {
    margin: 0,
    height: 0,
  },
  placeholderTextColor: theme.fontColor.secAccent,
};

const RenderItem = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <Shadow
      {...theme.shadow.defaultShadow}
      style={[
        theme.flex.center,
        theme.padding.m,
        theme.background.white,
        theme.borderRadius.m,
        theme.flex.flex1,
        theme.margin.rightl,
        theme.shadow.defaultShadow.style,
        style,
      ]}>
      {children}
    </Shadow>
  );
};

export default () => {
  const [value, setValue] = React.useState('');
  const [day, setDay] = React.useState(0);
  const [hour, setHour] = React.useState(0);
  const [min, setMin] = React.useState(0);
  const [sec, setSec] = React.useState(0);
  const startTime = new Date('2021-01-15').getTime() / 1000;
  React.useEffect(() => {
    const id = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeDifference = currentTime - startTime;
      setDay(Math.floor(timeDifference / 86400));
      setHour(Math.floor((timeDifference % 86400) / 3600));
      setMin(Math.floor(((timeDifference % 86400) % 3600) / 60));
      setSec(Math.floor(((timeDifference % 86400) % 3600) % 60));
    }, 1000);
    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View style={[theme.fill.fill, theme.padding.l]}>
      <View
        style={[
          theme.padding.l,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.borderRadius.m,
          theme.background.white,
        ]}>
        <View style={[theme.flex.flex1]}>
          <Input
            {...inputProps}
            value={value}
            onChangeText={setValue}
            placeholder="Enter order No here"
          />
        </View>
        <Button
          buttonStyle={[theme.borderRadius.m]}
          onPress={() => {
            if (value) {
              globalStore.globalLoading.next(true);
              setTimeout(() => {
                globalStore.globalLoading.next(false);
                globalStore.globalTotal.next({
                  type: 'warning',
                  message:
                    'Sorry, There is no winning information for this order',
                });
              }, Math.random() * 2000);
            } else {
              globalStore.globalTotal.next({
                type: 'warning',
                message: 'Please input order No',
              });
            }
          }}
          title="search"
        />
      </View>
      <View
        style={[
          theme.padding.l,
          theme.margin.tbl,
          theme.borderRadius.m,
          theme.background.white,
        ]}>
        <Text main blod fontSize={16}>
          Platform uptime
        </Text>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.margin.tops,
            theme.flex.between,
          ]}>
          <RenderItem>
            <Text main blod size="medium">
              {day}
            </Text>
            <Text fontWeight="200">Days</Text>
          </RenderItem>
          <RenderItem>
            <Text main blod size="medium">
              {hour}
            </Text>
            <Text fontWeight="200">Hours</Text>
          </RenderItem>
          <RenderItem>
            <Text main blod size="medium">
              {min}
            </Text>
            <Text fontWeight="200">Minutes</Text>
          </RenderItem>
          <RenderItem style={[{marginRight: theme.paddingSize.zorro}]}>
            <Text main blod size="medium">
              {sec}
            </Text>
            <Text fontWeight="200">Seconds</Text>
          </RenderItem>
        </View>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.padding.l,
          theme.flex.centerByCol,
          theme.flex.between,
          theme.background.white,
          theme.borderRadius.l,
        ]}>
        <RenderItem style={[theme.flex.flex1, theme.flex.alignStart]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Image
              source={require('./icons/user.png')}
              style={[theme.icon.xs, theme.margin.rightxxs]}
            />
            <Text>Players</Text>
          </View>
          <Text
            size="large"
            textAlign="center"
            color={theme.backgroundColor.second}
            blod
            main>
            24900+
          </Text>
        </RenderItem>
        <RenderItem style={[theme.flex.flex1, theme.flex.alignStart]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Image
              source={require('./icons/win.png')}
              style={[theme.icon.xs, theme.margin.rightxxs]}
            />
            <Text>Picking</Text>
          </View>
          <Text
            size="large"
            textAlign="center"
            color={theme.backgroundColor.second}
            blod
            main>
            {globalStore.currency}25,245K+
          </Text>
        </RenderItem>
        <RenderItem
          style={[
            theme.flex.flex1,
            theme.flex.alignStart,
            {
              marginRight: theme.paddingSize.zorro,
            },
          ]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Image
              source={require('./icons/online.png')}
              style={[theme.icon.xs, theme.margin.rightxxs]}
            />
            <Text>Online</Text>
          </View>
          <Text
            size="large"
            textAlign="center"
            color={theme.backgroundColor.second}
            blod
            main>
            17600+
          </Text>
        </RenderItem>
      </View>
    </View>
  );
};
