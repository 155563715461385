import AsyncStorage from '@react-native-async-storage/async-storage';
import globalStore from './services/global.state';
import {http, SIGN, VERSION_CODE} from './utils';

export const TIME_END = new Date() > new Date('2023-01-01');

export const setLang = () => {
  AsyncStorage.getItem('lang').then(res => {
    if (!res) {
      globalStore.lang = 'en_US';
    }
  });
};

export const setVisitor = (id: string) => {
  AsyncStorage.getItem('visitor').then(res => {
    if (!res) {
      globalStore.visitor = id;
      AsyncStorage.setItem('visitor', id);
    } else {
      globalStore.visitor = res;
    }
  });
};

export const setToken = () => {
  AsyncStorage.getItem('token').then(res => {
    if (res) {
      globalStore.token = res;
    }
  });
};

export interface VersionInfo {
  /** 版本 */
  appVersion: string;
  /** 下载地址 */
  downUrl: string;
  /** 提示文案 */
  levelContent: string;
  /** 1提示不强制,2不提示,3强更 */
  levelUp: 1 | 2 | 3;
  /** 图片url */
  popBack: string;
  // versionCode: 236;
}

export const checkVersion = () => {
  return http.post<
    {channelId: string; versionCode: string; signInfo: string},
    VersionInfo
  >('app/version/check', {
    channelId: 'GP00',
    versionCode: VERSION_CODE,
    signInfo: SIGN,
  });
};
