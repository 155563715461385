// Routes.js
import {LinkingOptions} from '@react-navigation/native';
import {HomeDetail} from './pages/home';
import {mainPageList} from './main-navigation';
import DetailWebView, {GameWebView} from './common-pages/webview';
import Login, {SetPassword, SingUp} from './common-pages/login';
import {BasicObject, NavigatorScreenProps} from './types';
import globalStore from './services/global.state';
import Demo from './common-pages/demo';
import {CasinoDetail} from './common-pages/casino';
import {OrganicKeralaDetail} from './organic';
import Splash from './common-pages/splash';
import Result from './common-pages/result';
import Referral from './pages/referral';
import PaidSuccess from './common-pages/paid-success';

type Routes = {
  name: string;
  component: (props: NavigatorScreenProps & BasicObject) => React.JSX.Element;
  link: string;
  headerShown?: boolean;
};

const routes: Routes[] = [
  {
    name: 'GameWebView',
    component: GameWebView,
    link: 'game-webview',
  },
  {
    name: 'HomeDetail',
    component: HomeDetail,
    link: 'home-detail',
  },
  {
    name: 'CasinoDetail',
    component: CasinoDetail,
    link: 'casino-detail',
  },
  {
    name: 'Result',
    component: Result,
    link: 'result',
  },
  {
    name: 'Login',
    component: Login,
    link: 'login',
  },
  {
    name: 'SingUp',
    component: SingUp,
    link: 'sing-up',
  },
  {
    name: 'SetPassword',
    component: SetPassword,
    link: 'set-password',
  },
  {
    name: 'WebView',
    component: DetailWebView,
    link: 'webview',
  },
  {
    name: 'Demo',
    component: Demo,
    link: 'demo',
  },
  {
    name: 'OrganicKeralaDetail',
    component: OrganicKeralaDetail,
    link: 'organic-kerala-detail',
  },
  {
    name: 'Splash',
    component: Splash,
    link: 'splash',
  },
  {
    name: 'Referral',
    component: Referral,
    link: 'referral',
  },
  {
    name: 'PaidSuccess',
    component: PaidSuccess,
    link: 'paid-success',
  },
];

const linking: LinkingOptions<{}> = {
  prefixes: ['https://singamlottery.com', 'singam://'],
  config: {
    screens: {
      Index: {
        screens: mainPageList.reduce((data, item) => {
          data[item.name] = item.link;
          return data;
        }, {} as {[k: string]: string}),
      },
      ...Object.values(routes).reduce((data, route) => {
        data[route.name] = route.link;
        return data;
      }, {} as {[k: string]: string}),
    },
  },
};

globalStore.setItem('linking', linking);

export {routes, linking};
