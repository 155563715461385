export default {
  loading: 'Loading...',
  'no-update': 'Already the latest version',
  home: {
    tab: {
      home: 'Home',
      result: 'Result',
      casino: 'Casino',
      scratch: 'Scratch',
      referral: 'Referral',
      me: 'Me',
      live: 'Live',
    },
    scratch: {
      free: 'FREE',
    },
    kerala: {
      title: 'Kerala State Lottery',
      no: 'NO.',
    },
    digit: {
      title: '3 Digit Game',
      booking: 'Time for Next Booking',
      ticket: 'Ticket',
    },
    dice: {
      title: 'Dice Game',
      play: 'Play Now',
    },
    color: {
      title: 'Color Prediction',
    },
    matka: {
      bid: 'BID',
      win: 'WIN',
      open: 'OPEN',
      close: 'CLOSE',
      title: 'Satta Matka',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    tip: {
      closed: '{{name}} is closed now, please choose next game',
      copied: 'Copy successfully, please share with friends',
    },
    label: {
      menu: 'Menu',
      viewAll: 'View All',
      tryNow: 'Try Now',
      'lucky-spin': 'Lucky Spin',
      rebate: 'Rebate',
      'free-lottery': 'Free Lottery',
    },
    luckyspin: {
      add: 'Add',
      spin: 'SPIN',
      won: 'Won',
      winner: 'Big Winners',
      mySpin: 'My Spin',
    },
  },
  me: {
    webview: {
      vip: 'Member Center',
    },
    new: 'NEW',
    user: {
      userId: 'User ID',
      moreExciting: 'More exciting after logging in',
      login: 'Log in',
      loginUpper: 'LOGIN',
    },
    money: {
      totalWallet: 'Total Wallet',
      cashBalance: 'Cash Balance',
      withdrawAmount: 'Withdrawable Amount',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level ',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result history',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer service',
      update: 'Update',
      password: 'Password',
    },
    language: {
      switch: 'Switch Language',
      english: 'English',
    },
    tip: {
      cashTitle: 'Cash Balance:',
      cashContent:
        '"Cash balance" refers to the actual cash funds available in your account that can be used for betting or other transactions.',
      withdrawTitle: 'Withdrawable Amount:',
      withdrawContent:
        '"Withdrawable amount" is the portion of your cash balance that can be withdrawn from your account. It represents the money that has met the wagering requirements, which need to be fulfilled before the funds can be withdrawn.',
    },
  },
  casino: {
    big: 'BIG',
    win: 'WIN',
    detail: {
      title: 'Congratulations to the lucky players',
    },
    winPrize: 'WIN PRIZE',
    playnow: 'Play Now',
  },
  login: {
    label: {
      login: 'LOGIN',
      skip: 'Skip',
      sign: 'Sign Up',
      next: 'NEXT',
      'select-region': 'Select Region',
      'otp-login': 'OTP Login',
      'password-login': 'Password Login',
      'get-otp': 'GET OTP',
      'set-password': 'Set Password',
    },
    tip: {
      phone: 'Enter Phone Numbe',
      password: 'Enter Password(6-18)',
      'confirm-password': 'Confirm Password',
      forgot: 'Forgot your password?',
      'new-account': 'Need an account?',
      'sing-in': 'SIGN UP',
      otp: 'Enter OTP',
      'referral-code': 'Referral Code',
      confrim18: 'I confirm I am 18+',
      'allow-notify':
        'Allow us to notify you important winning information through this phone number.',
      'has-account': 'Already a user?',
      'sign-in': 'LOGIN',
      page18: 'I confirm I am 18+',
      notify:
        'Allow us to notify you important winning informationthrough this mobile number .',
      'pwd-dif': 'The tow passwords is inconsistent',
      modified:
        'The password has been successfully modified. Please log in again',
    },
  },
  share: {
    'copy-success': 'Copy Successfully',
  },
  paidSuccess: {
    label: {
      title: 'Payment',
      subTitle: 'Paid successfully',
      viewOrder: 'View Order',
      backHome: 'Back Home',
    },
    tip: {
      msg: 'Your tickets have been successfully purchased. Please take note of the draw time and check the results promptly.',
    },
  },
};
