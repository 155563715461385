export default {
  label: {
    ok: 'Ok',
    confirm: 'Confirm',
    cancel: 'Cancel',
    download: 'Download',
    submit: 'Submit',
    search: 'Search',
  },
  tip: {
    success: 'Successfully',
  },
  text: {},
  defaultPage: {
    noMore: 'No more data',
  },
  home: {
    scratchOff: 'Scratch Off',
    copied: 'Copy successfully, please share with friends',
  },
  alert: {
    logout: 'LOG OUT',
    sureLogout: 'Are you sure you want to log out?',
  },
  warning: {
    unopen: 'Not yet enabled',
  },
  vip: {
    currentLevel: 'Current Level',
  },
  referral: {
    copy: 'Copy',
    invite: 'Invite',
    copied: 'Copy successfully',
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'Something went terribly wrong !',
        'sub-title': 'Please refresh your page',
        'try-again': 'Try Again',
      },
    },
    'no-data': {
      label: {
        title: 'No Data',
      },
    },
    'not-found': {
      label: {
        title: 'The current network signal is poor',
        'sub-title': 'please check your network',
      },
    },
    'no-result': {
      label: {
        title: 'No search result !',
      },
    },
  },
  share: {
    copy: 'Copy',
    invite: 'Invite',
    'copy-success': 'Copy Successfully',
    copied: 'Copy successfully',
  },
  casino: {
    big: 'BIG',
    win: 'WIN',
    detail: {
      title: 'Congratulations to the lucky players',
    },
    winPrize: 'WIN PRIZE',
    playnow: 'Play Now',
  },
  login: {
    label: {
      login: 'LOGIN',
      skip: 'Skip',
      sign: 'Sign Up',
      next: 'NEXT',
      'select-region': 'Select Region',
      'otp-login': 'OTP Login',
      'password-login': 'Password Login',
      'get-otp': 'GET OTP',
      'set-password': 'Set Password',
    },
    tip: {
      phone: 'Enter Phone Number',
      password: 'Enter Password(6-18)',
      'confirm-password': 'Confirm Password',
      forgot: 'Forgot your password?',
      'new-account': 'Need an account?',
      'sing-in': 'SIGN UP',
      otp: 'Enter OTP',
      'referral-code': 'Referral Code',
      confrim18: 'I confirm I am 18+',
      'allow-notify':
        'Allow us to notify you important winning information through this phone number.',
      'has-account': 'Already a user?',
      'sign-in': 'LOGIN',
      page18: 'I confirm I am 18+',
      notify:
        'Allow us to notify you important winning informationthrough this mobile number .',
      'pwd-dif': 'The tow passwords is inconsistent',
      modified:
        'The password has been successfully modified. Please log in again',
    },
  },
  paidSuccess: {
    label: {
      title: 'Payment',
      subTitle: 'Paid successfully',
      viewOrder: 'View Order',
      backHome: 'Back Home',
    },
    tip: {
      msg: 'Your tickets have been successfully purchased. Please take note of the draw time and check the results promptly.',
    },
  },
};
