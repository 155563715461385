import {BasicObject} from '../types';

export const getUUID = () => {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    const index = Math.floor(Math.random() * 0x10);
    s[i] = hexDigits.substring(index, index + 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  const index = (s[19] && 0x3) || 0x8;
  s[19] = hexDigits.substring(index, index + 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23];
  return s.join('');
};

export const getUrlParams = (search: string = location.href.split('?')[1]) => {
  const params: BasicObject = {};
  const searchParams = new URLSearchParams(search).entries();
  for (const [key, value] of searchParams) {
    params[key] = value;
  }
  return params;
};

export const objectToUrlParams = (obj: BasicObject) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const serializedValue =
        typeof value === 'object' ? JSON.stringify(value) : value;
      params.append(key, serializedValue);
    }
  }
  return params.toString();
};

/** 基础节流 */
export function throttle<T extends any[]>(
  fn: (...args: T) => void,
  delay: number = 200,
): (this: any, ...args: T) => void {
  let lastTime = 0;
  let timer: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: T) {
    const currentTime = Date.now();

    if (currentTime - lastTime < delay) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
        lastTime = currentTime;
      }, delay);
    } else {
      fn.apply(this, args);
      lastTime = currentTime;
    }
  };
}

/** 基础防抖 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number = 200,
): (...args: Parameters<T>) => void {
  let timerId: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>) {
    timerId && clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

/** 会直接触发一次,然后再防抖 */
export function debounceWithImmediate<T extends (...args: any[]) => any>(
  func: T,
  delay: number = 200,
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | null = null;
  let isFirstExecution = true;

  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId!);

    if (isFirstExecution) {
      isFirstExecution = false;
      func.apply(this, args);
    }

    timeoutId = setTimeout(() => {
      isFirstExecution = true;
    }, delay);
  };
}

/**
 * 基础的时间格式化方法
 * @param date 需要格式化的时间,可以是字符串或者数字,如果是数字,会自动*1000
 * @param format 会将字符串中的yyyy/MM/dd/hh/mm/ss替换为具体的值
 * @returns
 */
export function formatDate(
  date: Date | string | number,
  format: string = 'yyyy-MM-dd hh:mm:ss',
): string {
  if (!(date instanceof Date)) {
    if (typeof date === 'number') {
      date = date * 1000;
    }
    date = new Date(date);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  }
  const year = date.getUTCFullYear().toString();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  return format
    .replace('yyyy', year)
    .replace('MM', month)
    .replace('dd', day)
    .replace('hh', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);
}

/**
 * 将列表转换为分组列表
 * @param list 源列表
 * @param groupCount 一组有多少个
 */
export function getSeperateList<T>(list: T[], groupCount: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < list.length; i += groupCount) {
    result.push(list.slice(i, i + groupCount));
  }
  return result;
}

/**
 * 在使用Promise.allSettled后,对useState进行赋值
 * @param setFun set方法
 * @param result promise返回的result
 * @param nullData 无值时的填充,默认为[],可按需传入其他数据
 */
export const setDataForSettled = <T, D>(
  setFun: React.Dispatch<React.SetStateAction<T | D>>,
  result: PromiseSettledResult<T>,
  nullData: D = [] as D,
) => {
  setFun(result.status === 'fulfilled' ? result.value || nullData : nullData);
};
