import i18n from '@/i18n';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@style';
import React from 'react';

const NoMoreData = ({text = i18n.t('defaultPage.noMore')}: {text?: string}) => {
  return (
    <View style={[theme.flex.center, theme.padding.tbl]}>
      <Text
        fontSize={theme.fontSize.m}
        style={[theme.font.secAccent, theme.margin.tbl]}>
        {text}
      </Text>
    </View>
  );
};

export default NoMoreData;
