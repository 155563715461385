import React from 'react';
import {ImageBackground, Image, View} from 'react-native';
import Text from '@basicComponents/text';
import {goBack, http} from '@/utils';
import theme from '@/style';
import {FlatList} from 'react-native-gesture-handler';
import {useRoute} from '@react-navigation/native';
import NavTitle from '@basicComponents/nav-title';
import {BasicObject} from '@/types';
import globalStore from '@/services/global.state';

type BounItem = {
  drawLevel: string;
  lotteryResultList: {
    drawAmount: number;
    drawLevel: string;
    lotteryCode: string;
  }[];
};

export default () => {
  const [list, setList] = React.useState<BounItem[]>([]);
  const {drawDate, issueNo} = useRoute().params as BasicObject;
  const [isLoading, setIsLoading] = React.useState(true);
  const getList = () => {
    globalStore.globalLoading.next(true);
    http
      .post<{type: string}, {groupLevelList: BounItem[]}>(
        '/app/lottery/result/groupLevelList',
        {
          issueNo: issueNo,
        },
      )
      .then(res => {
        setList(res.groupLevelList);
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={[theme.fill.fill]}>
      <NavTitle onBack={() => goBack()}>
        <View style={theme.flex.center}>
          <Text main size="medium">
            {issueNo}
          </Text>
          <Text accent fontSize={9}>
            {drawDate}
          </Text>
        </View>
      </NavTitle>

      {list.length > 0 ? (
        <FlatList
          data={list}
          renderItem={({item}) => BounCard(item)}
          style={[theme.fill.fill, theme.padding.l]}
        />
      ) : (
        <View style={[theme.padding.l, theme.flex.center]}>
          <Text main blod size="large">
            {isLoading ? 'Loading...' : 'No Result'}
          </Text>
        </View>
      )}
    </View>
  );
};

const imgMap: BasicObject = {
  '1st': require('./imgs/top-card/1st.webp'),
  Cons: require('./imgs/top-card/cons.webp'),
  '2nd': require('./imgs/top-card/2nd.webp'),
  '3rd': require('./imgs/top-card/3rd.webp'),
  '4th': require('./imgs/top-card/4th.webp'),
  '5th': require('./imgs/top-card/5th.webp'),
  '6th': require('./imgs/top-card/6th.webp'),
  '7th': require('./imgs/top-card/7th.webp'),
  '8th': require('./imgs/top-card/8th.webp'),
  '9th': require('./imgs/top-card/9th.webp'),
  '10th': require('./imgs/top-card/10th.webp'),
  '11th': require('./imgs/top-card/11th.webp'),
  Lucky: require('./imgs/top-card/lucky.webp'),
  banknotes: require('./imgs/top-card/banknotes.webp'),
};
const cardImageHeight =
  ((globalStore.screenWidth - theme.paddingSize.l * 2) * 56) / 351;
const numberWidth = globalStore.screenWidth / 3 - theme.paddingSize.l * 2;

const BounCard = (card: BounItem) => {
  let color;
  switch (card.drawLevel) {
    case '1st':
    case 'Cons':
      color = theme.backgroundColor.second;
      break;
    case '2nd':
      color = theme.basicColor.primary;
      break;
    case '3rd':
      color = theme.backgroundColor.secAccent;
      break;
    default:
      color = theme.fontColor.accent;
  }
  return imgMap[card.drawLevel] && card.lotteryResultList.length > 0 ? (
    <View
      key={card.drawLevel}
      style={[
        theme.background.white,
        theme.borderRadius.m,
        theme.margin.btml,
        theme.overflow.hidden,
      ]}>
      <ImageBackground
        source={imgMap[card.drawLevel]}
        resizeMode="contain"
        style={{height: cardImageHeight}}>
        <View
          style={[
            theme.padding.l,
            theme.padding.btmxxl,
            theme.flex.end,
            theme.flex.row,
          ]}>
          <Text main>Bouns </Text>
          <Text blod color={color}>
            {' '}
            {card.lotteryResultList[0].drawAmount}
          </Text>
          <Image
            source={imgMap.banknotes}
            style={[theme.icon.s, theme.margin.lefts]}
          />
        </View>
      </ImageBackground>
      <View
        style={[
          card.lotteryResultList.length > 2 ? theme.padding.topl : null,
          theme.padding.leftl,
          theme.flex.row,
          card.lotteryResultList.length > 2
            ? theme.flex.wrap
            : theme.flex.center,
        ]}>
        {card.lotteryResultList.map((v, i) => (
          <View
            key={i}
            style={[
              {
                width: numberWidth,
              },
              theme.background.lightGrey,
              theme.borderRadius.xl,
              theme.padding.tbm,
              theme.margin.rightl,
              theme.margin.btml,
            ]}>
            <Text main textAlign="center">
              {v.lotteryCode}
            </Text>
          </View>
        ))}
      </View>
    </View>
  ) : null;
};
