import Text from '@basicComponents/text';
import theme from '@/style';
import React, {Component} from 'react';
import {
  ImageURISource,
  FlatList,
  StyleSheet,
  View,
  NativeSyntheticEvent,
  NativeScrollEvent,
  LayoutChangeEvent,
  Image,
} from 'react-native';
import i18n from '@/i18n';
import HomeFloorBtn from './home-floor-btn';
import HomeFloorTitleBox from './home-floor-title-box';

interface HomeFloorBoxProps<listItemT> {
  /** 数据列表 */
  list: listItemT[];
  /** 渲染item,会返回数据列表的项,以及index */
  renderItem: (item: listItemT, index: number) => React.ReactElement;
  title: string;
  /** 标题背景图 */
  titleBgImg?: ImageURISource;
  titleIcon: ImageURISource;
  titleIconSize?: number;
  /** 每个元素的宽度,这个加上偏移量为每次拖动的距离 */
  itemWidth: number;
  /** 偏移量(中间padding的距离,默认theme.paddingSize.l) */
  offsetSize?: number;
  /** 当点击viewAll时触发的方法,如果不传,不会显示viewAll的按钮 */
  onPressViewAll?: () => void;
}

const styles = StyleSheet.create({
  pageIcon: {
    width: 6,
    height: 9,
  },
});

class HomeFloorBox<listItemT> extends Component<
  HomeFloorBoxProps<listItemT>,
  {
    leftDisabled: boolean;
    rightDisabled: boolean;
    currentIndex: number;
    lastOffset: number;
    /** 滚动区域一屏的宽度 */
    listLayoutWidth: number;
    /** 偏移量(中间padding的距离,默认theme.paddingSize.s) */
    offsetSize: number;
  }
> {
  constructor(props: HomeFloorBoxProps<listItemT>) {
    super(props);
    this.state = {
      leftDisabled: true,
      rightDisabled: false,
      currentIndex: 0,
      lastOffset: 0,
      listLayoutWidth: 0,
      offsetSize: props.offsetSize || theme.paddingSize.s,
    };
  }
  flatListRef = React.createRef<FlatList>();
  handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const {contentOffset, layoutMeasurement, contentSize} = event.nativeEvent;
    this.setState(() => ({
      leftDisabled: contentOffset.x === 0,
      rightDisabled:
        Math.floor(contentOffset.x + layoutMeasurement.width) >=
        Math.floor(contentSize.width),
      lastOffset: contentOffset.x,
    }));
  };
  layoutList = (event: LayoutChangeEvent) => {
    event.persist && event.persist();
    this.setState(() => ({
      listLayoutWidth: event.nativeEvent.layout.width,
    }));
  };
  scrollListTo = (type: 1 | -1) => {
    this.scrollListToOffsetX(
      this.state.lastOffset +
        (this.state.listLayoutWidth + this.state.offsetSize) * type,
    );
  };
  scrollListToOffsetX = (num: number) => {
    this.flatListRef.current!.scrollToOffset({
      animated: true,
      offset: Math.round(num),
    });
  };
  render() {
    const {
      list,
      renderItem,
      itemWidth: itemWidth,
      onPressViewAll,
      titleIcon,
      title,
      titleIconSize,
    } = this.props;
    return (
      <View style={[theme.fill.fillW, theme.flex.col]}>
        <HomeFloorTitleBox
          style={[theme.fill.fillW]}
          titleBgImg={this.props.titleBgImg}
          linear>
          <View
            style={[
              theme.flex.row,
              theme.padding.tbs,
              theme.padding.lrl,
              theme.flex.centerByCol,
            ]}>
            <Image
              style={[
                theme.icon.l,
                theme.margin.rights,
                titleIconSize
                  ? {width: titleIconSize, height: titleIconSize}
                  : {},
              ]}
              source={titleIcon}
            />
            <Text size="medium" main fontFamily="fontInterBold">
              {title}
            </Text>
            <View style={theme.flex.flex1} />
            {onPressViewAll && (
              <HomeFloorBtn
                linerGradientContainerStyle={[
                  theme.padding.lrl,
                  theme.padding.tbxs,
                ]}
                onPress={onPressViewAll}>
                <Text
                  fontSize={theme.fontSize.s}
                  color={theme.fontColor.accent}
                  fontFamily="fontInterBold"
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{lineHeight: 16}}>
                  {i18n.t('home.label.viewAll')}
                </Text>
              </HomeFloorBtn>
            )}
            <HomeFloorBtn
              disabled={this.state.leftDisabled}
              style={theme.margin.lrxs}
              linerGradientContainerStyle={[theme.icon.xl, theme.flex.center]}
              onPress={() => {
                this.scrollListTo(-1);
              }}>
              <Image
                style={styles.pageIcon}
                source={require('@assets/icons/home/to-left.webp')}
              />
            </HomeFloorBtn>
            <HomeFloorBtn
              disabled={this.state.rightDisabled}
              linerGradientContainerStyle={[theme.icon.xl, theme.flex.center]}
              onPress={() => {
                this.scrollListTo(1);
              }}>
              <Image
                style={styles.pageIcon}
                source={require('@assets/icons/home/to-right.webp')}
              />
            </HomeFloorBtn>
          </View>
        </HomeFloorTitleBox>
        <FlatList
          ref={this.flatListRef}
          onLayout={this.layoutList}
          data={list}
          horizontal
          style={[theme.margin.lrl, theme.margin.btml]}
          snapToInterval={itemWidth + this.state.offsetSize}
          onScroll={this.handleScroll}
          pagingEnabled={true}
          showsHorizontalScrollIndicator={false}
          renderItem={({item, index}) => renderItem(item, index)}
        />
      </View>
    );
  }
}

export default HomeFloorBox;
