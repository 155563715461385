import {Platform} from 'react-native';
import RNConfig from 'react-native-config';

const IS_WEB = Platform.OS === 'web';

const ENV_CONFIG = (IS_WEB ? process.env : RNConfig) as {
  REACT_APP_ENV: 'dev' | 'test' | 'prod';
  REACT_APP_API_BASE_URL: string;
  REACT_APP_API_UNI_URL: string;
  REACT_APP_API_INDUSWIN_URL?: string;
  REACT_APP_API_SCRATCH_URL?: string;
  REACT_APP_API_H5GAMES_URL?: string;
  [k: string]: string | number | undefined;
};

class Config {
  private _appToken = IS_WEB
    ? 'cqgcd6lp6jgg' // singam h5
    : 'bmjnxo64u0hs'; // singam ab03;
  get appToken() {
    return this._appToken;
  }
  private _baseUrl: string;
  get baseUrl() {
    return this._baseUrl;
  }
  private _uniUrl: string;
  get uniUrl() {
    return this._uniUrl;
  }
  private _induswinUrl: string | undefined;
  get induswinUrl() {
    return this._induswinUrl;
  }
  // private _reactH5Url = 'http://192.168.10.33:5500';
  private _reactH5Url = 'https://h5-games-test.pages.dev';
  get reactH5Url() {
    return this._reactH5Url;
  }
  private _scratchUrl: string | undefined;
  get scratchUrl() {
    return this._scratchUrl;
  }
  private _customerServerUrl = 'https://singamlottery.com/singam-service/';
  get customerServerUrl() {
    return this._customerServerUrl;
  }
  constructor() {
    console.log(this._appToken);
    this._baseUrl = ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._uniUrl =
      ENV_CONFIG.REACT_APP_API_UNI_URL || 'https://singam-uni-view.pages.dev';
    this._induswinUrl =
      ENV_CONFIG.REACT_APP_API_INDUSWIN_URL ||
      ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._reactH5Url = ENV_CONFIG.REACT_APP_API_H5GAMES_URL || this._reactH5Url;
    this._scratchUrl = ENV_CONFIG.REACT_APP_API_SCRATCH_URL;
  }
}

const config = new Config();

export default config;
