import {View, ListRenderItemInfo, StyleSheet, FlatList} from 'react-native';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import theme from '@style';
import Card, {lightLinear} from '@basicComponents/card';
import React from 'react';
import Text from '@basicComponents/text';
import {goTo, toPriceStr} from '@utils';
import {BigWinnerItem} from '../casino.service';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {defaultHeader, smallWinnerIcon} from '../casino.variable';
import globalStore from '@/services/global.state';
import {useFlatListAutoPlay} from '../../hooks/autoplay.hooks';
import BigWin from './bigwin';

const styles = StyleSheet.create({
  winIcon: {
    right: 0,
    bottom: 0,
  },
  winResult: {
    left: -theme.paddingSize.xxs / 2,
    bottom: globalStore.isWeb ? 47 : 51,
  },
  cardBottom: {
    marginBottom: theme.paddingSize.xxs / 2,
  },
  cardTitle: {
    height: 42,
  },
});
// console.log(bigWinnerList);

export interface CasinoBigWinnerItem {
  header?: string;
  game: string;
  name: string;
  gameImage: string;
  prizeMoney: number;
}

export interface CasinoBigWinProps {
  bigWinnerList: BigWinnerItem[];
}
const winIcon = require('../assets/win.webp');

const renderGameItem = ({item}: ListRenderItemInfo<BigWinnerItem>) => {
  const cardImageSize = (globalStore.screenWidth * 136) / 375;
  const headerSize = (globalStore.screenWidth * theme.iconSize.l) / 375;
  return (
    <TouchableOpacity
      onPress={() =>
        goTo('CasinoDetail', {
          gameCode: item.gameCode,
          gameName: item.gameName,
          jackpotAmount: item.jackpotAmount,
        })
      }>
      <View style={[theme.margin.lefts, theme.position.rel]}>
        <Card
          radius={theme.borderRadiusSize.m}
          paddingHorizontal={theme.paddingSize.xs}
          paddingVertical={theme.paddingSize.xs}
          backgroundColor={theme.basicColor.white}>
          <Card.Image
            radius={theme.borderRadiusSize.s}
            imageUrl={item.bigWinsImg}
            width={cardImageSize}
            height={cardImageSize}
            style={[theme.flex.col, theme.flex.end]}
          />
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.start,
              theme.padding.tops,
              styles.cardBottom,
            ]}>
            <LazyImageBackground
              imageUrl={item.bigWinnerDto.avatar || defaultHeader}
              width={headerSize}
              height={headerSize}
              style={[theme.position.rel, theme.overflow.hidden]}
              radius={headerSize / 2}>
              <View style={[styles.winIcon, theme.position.abs]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={smallWinnerIcon}
                  width={10}
                  height={10}
                />
              </View>
            </LazyImageBackground>
            <View style={[theme.margin.lefts]}>
              <Text fontSize={9} style={[theme.font.secAccent]}>
                {item.gameName}
              </Text>
              <Text fontSize={theme.fontSize.s} style={[theme.font.second]}>
                {item.bigWinnerDto.userName}
              </Text>
            </View>
          </View>
        </Card>
        <View
          style={[
            theme.position.abs,
            styles.winResult,
            theme.flex.row,
            theme.flex.centerByCol,
            theme.margin.rightxxs,
          ]}>
          <BigWin />
          <Text
            fontSize={theme.fontSize.l}
            style={[theme.font.white, theme.margin.lefts]}
            fontFamily="fontDin"
            fontWeight="900">
            {toPriceStr(item.jackpotAmount, {
              currency: globalStore.currency,
              thousands: true,
              fixed: 0,
            })}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const CasinoBigWin: React.FC<CasinoBigWinProps> = ({bigWinnerList}) => {
  const ITEM_SIZE = 156;
  const {flatListRef, handleScroll} = useFlatListAutoPlay(bigWinnerList, {
    overListLength: 3,
    canPlay: true,
    timeout: 3000,
    row: true,
    size: ITEM_SIZE,
  });
  return (
    <View
      style={[
        theme.margin.topl,
        // eslint-disable-next-line react-native/no-inline-styles
        {minHeight: 236},
      ]}>
      <Card radius={theme.borderRadiusSize.m}>
        <Card.Title
          icon={{
            url: winIcon,
            width: theme.iconSize.m,
            height: theme.iconSize.m,
          }}
          style={[styles.cardTitle]}
          title="Recent Big Wins"
          linear={lightLinear}
        />
        {/* 这一块是从左到右的内容 */}
        <View style={[theme.padding.lrl, theme.overflow.hidden]}>
          <FlatList
            ref={flatListRef}
            horizontal={true}
            renderItem={renderGameItem}
            data={bigWinnerList}
            onScroll={handleScroll}
            showsHorizontalScrollIndicator={false}
            getItemLayout={(data, index) => ({
              length: ITEM_SIZE,
              offset: ITEM_SIZE * index,
              index,
            })}
          />
        </View>
      </Card>
    </View>
  );
};

export default CasinoBigWin;
