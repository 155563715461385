import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {IUserInfo} from '@services/global.service';
import {View} from 'react-native';
import theme from '@style';
import React from 'react';
import {rightIcon, rightIconSize, smallHeaderSize} from './me.variable';
import LazyImage from '@basicComponents/image';
import Text from '@basicComponents/text';
import i18n from '@/i18n';
import globalStore from '@/services/global.state';

interface MeHeaderProps {
  user?: IUserInfo;
  login: boolean;
  float: boolean;
  showNoMenu?: boolean;
  onUser: () => void;
}
const {flex, padding, font, margin, background} = theme;
const emptyHeaderImg = require('@components/assets/icons/header.webp');
const defaultHeaderImg = require('@components/assets/icons/default-header.webp');

const MeHeader: React.FC<MeHeaderProps> = ({
  user,
  login,
  float,
  onUser,
  showNoMenu,
}) => {
  return float ? (
    <NativeTouchableOpacity activeOpacity={1} onPress={onUser}>
      <View
        style={[
          flex.row,
          padding.l,
          flex.between,
          flex.centerByCol,
          background.white,
        ]}>
        <View style={[flex.row, margin.lrs, flex.centerByCol]}>
          <View style={[margin.rightl]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={globalStore.token ? defaultHeaderImg : emptyHeaderImg}
              width={smallHeaderSize}
              height={smallHeaderSize}
            />
          </View>

          {login ? (
            <Text blod style={[font.second, font.fm, margin.rights]}>
              {user?.userPhone}
            </Text>
          ) : (
            <Text style={[font.accent, font.fm, margin.rights]}>
              {i18n.t('me.user.login')}
            </Text>
          )}
        </View>
        {showNoMenu && (
          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={rightIconSize}
            height={rightIconSize}
          />
        )}
      </View>
    </NativeTouchableOpacity>
  ) : (
    <View />
  );
};

export default MeHeader;
