import AsyncStorage from '@react-native-async-storage/async-storage';
import {BehaviorSubject, Subject} from 'rxjs';
import {CurrencyMap} from './currency';
import {BasicObject, SafeAny} from '@types';
import {Dimensions, Platform} from 'react-native';

interface H5AdjustEvent {
  eventToken: string;
  /** 与事件关联的金额 */
  revenue?: number;
  /** 金额的币种,如果有金额,必须带币种 */
  currency?: string;
  /** 唯一事件id,新事件会覆盖旧事件 */
  deduplicationId?: string;
  /** 回调id */
  callbackId?: string;
  /** 回调参数 */
  callbackParameters?: {key: string; value: string}[];
  /** 合作伙伴参数 */
  partnerParameters?: {key: string; value: string}[];
}

class GlobalStore {
  disabledPhoneCode = true;
  fullScreen = false;
  globalLoading = new BehaviorSubject<boolean>(false);
  globalTotal = new Subject<{
    type: 'success' | 'warning';
    message: string;
  }>();
  globalSucessTotal = (message: string) => {
    this.globalTotal.next({
      type: 'success',
      message,
    });
  };
  globalWaringTotal = (message: string) => {
    this.globalTotal.next({
      type: 'warning',
      message,
    });
  };
  isWeb = Platform.OS === 'web';
  isAndroid = Platform.OS === 'android';
  /** 渠道 */
  private _channel: string = null!;
  get channel() {
    return this._channel;
  }
  set channel(c: string) {
    this._channel = c;
    this.asyncSetItem('channel', c);
  }
  isIOS = Platform.OS === 'ios';
  asyncGetItem = (key: string) => {
    return AsyncStorage.getItem(key);
  };
  asyncRemoveItem = (key: string) => {
    AsyncStorage.removeItem(key);
  };
  asyncSetItem = (key: string, value: SafeAny) => {
    AsyncStorage.setItem(key, value);
  };
  private _token: string | null = null!;
  get token() {
    return this._token;
  }
  set token(token: string | null) {
    if (token) {
      this._token = token;
      this.tokenSubject.next(token);
      this.asyncSetItem('token', token);
    } else {
      this.clearToken();
    }
  }
  clearToken = () => {
    this._token = null;
    this.asyncRemoveItem('token');
    this.tokenSubject.next(null);
  };
  tokenSubject = new BehaviorSubject<string | null>(this._token);

  private _topwindowurl: string | null = null;
  get topwindowurl() {
    return this._topwindowurl;
  }
  set topwindowurl(topwindowurl: string | null) {
    this._topwindowurl = topwindowurl;
  }

  private _visitor: string | null = null!;
  /** 唯一标识 */
  get visitor() {
    return this._visitor;
  }
  set visitor(visitor: string | null) {
    this._visitor = visitor;
    if (visitor) {
      this.asyncSetItem('visitor', visitor);
    } else {
      this.asyncRemoveItem('visitor');
    }
  }

  private _lang: string = 'en_US';
  /** 语言 */
  get lang() {
    return this._lang;
  }
  set lang(lang: string) {
    this._lang = lang;
    this.langSubject.next(lang);
    if (lang) {
      this.asyncSetItem('lang', lang);
    } else {
      this.asyncRemoveItem('lang');
    }
  }
  /** 语言 */
  langSubject = new BehaviorSubject<string>(this._lang);

  private _currency: string = CurrencyMap.default;
  /** 当前币种 */
  get currency() {
    return this._currency;
  }
  set currency(currency: string) {
    this._currency = currency;
    this.langSubject.next(currency);
    if (currency) {
      this.asyncSetItem('currency', currency);
    } else {
      this.asyncRemoveItem('currency');
    }
  }
  /** 当前币种 */
  currencySubject = new BehaviorSubject<string>(this._currency);

  private _userAmount: number = 0;
  private _userLastAmount: number | null = null;
  /** 用户当前金额 */
  get userAmount() {
    return this._userAmount;
  }

  private _packageId = 3;
  get packageId() {
    return this._packageId;
  }

  private _packageInfo = 'com.sambad.kerala';
  get packageInfo() {
    return this._packageInfo;
  }

  /** app.tsx中处理,其他地方应该避免订阅 */
  updateAmount = new Subject<void>();
  private _amountChanged = new BehaviorSubject<{
    last: number | null;
    current: number;
  }>({
    last: null,
    current: 0,
  });
  /** app.tsx中统一触发,会有200ms的节流,避免频繁触发 */
  get amountChanged() {
    return this._amountChanged.asObservable();
  }
  /** 通过updateAmount在app.tsx中统一触发,其他地方应该避免订阅 */
  setAmount(amount: number) {
    this._amountChanged.next({
      last: this._userLastAmount,
      current: amount,
    });
    this._userLastAmount = this._userAmount = amount;
  }

  /** 杂项数据存取 */
  private dataMap: BasicObject = {};
  getItem = (key: string): string | BasicObject | null => {
    if (
      this.dataMap[key] ||
      this.dataMap[key] === 0 ||
      this.dataMap[key] === '' ||
      this.dataMap[key] === null
    ) {
      return this.dataMap[key];
    }
    return null;
  };
  setItem = (
    key: string,
    value: string | number | BasicObject | any[],
    saveToStorage = false,
  ) => {
    if (value || value === 0 || value === '' || value === null) {
      this.dataMap[key] = value;
      if (saveToStorage) {
        const _value =
          typeof value === 'string' ? value : JSON.stringify(value);
        this.asyncSetItem(key, _value);
      }
    }
  };
  removeItem = (key: string) => {
    delete this.dataMap[key];
    this.asyncRemoveItem(key);
  };

  appDistory = new Subject<boolean>();
  adjustEventMap: {[k: string]: string} = {};
  /** 需要传递AdjustEvent */
  adjustEvent = new Subject<SafeAny>();
  adjustEventH5 = new Subject<H5AdjustEvent>();
  /** 触发adjustEvent,方法内部会判断触发rn还是h5 */
  triggerAdjustEvent = (eventName: string, config?: H5AdjustEvent) => {
    const eventToken = this.adjustEventMap[eventName];
    if (!eventToken) {
      return console.error(`${eventName} is not found event token`);
    }
    if (Platform.OS === 'web') {
      this.adjustEventH5.next({eventToken, ...config});
    } else {
      const {AdjustEvent} = require('react-native-adjust');
      const event = new AdjustEvent(eventToken);
      if (config) {
        if (config.revenue && config.currency) {
          event.setRevenue(config.revenue, config.currency);
        }
        if (config.deduplicationId) {
          event.setTransactionId(config.deduplicationId);
        }
        if (config.callbackId) {
          event.setCallbackId(config.callbackId);
        }
        if (config.callbackParameters?.length) {
          config.callbackParameters.forEach(keymap => {
            event.addCallbackParameter(keymap.key, keymap.value);
          });
        }
        if (config.partnerParameters?.length) {
          config.partnerParameters.forEach(keymap => {
            event.addPartnerParameter(keymap.key, keymap.value);
          });
        }
      }
      this.adjustEvent.next(event);
    }
  };
  private _screenWidth = Dimensions.get('window').width;
  get screenWidth() {
    return this._screenWidth;
  }
  private _screenHeight = Dimensions.get('window').height;
  get screenHeight() {
    return this._screenHeight;
  }

  updateDimensions = () => {
    requestAnimationFrame(() => {
      this._screenHeight = Dimensions.get('window').height;
      this._screenWidth = Dimensions.get('window').width;
      this.resizeSubject.next({
        width: this._screenWidth,
        height: this._screenHeight,
      });
    });
  };

  resizeSubject = new BehaviorSubject<{width: number; height: number}>({
    width: this._screenWidth,
    height: this._screenHeight,
  });
}

/** 组件中避免依赖GlobalStore!!!!!! */
const globalStore = new GlobalStore();

export default globalStore;
