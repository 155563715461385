import i18nJSON from '@components/i18n';
import {deepMerge} from '@components/utils/object';
import i18n from 'react-native-i18n';
import en_US from './en_US';

i18n.defaultLocale = 'en_US';
i18n.fallbacks = true;
i18n.translations = deepMerge(i18nJSON, {en_US});

export default i18n;

export const languagesMap: {[k: string]: string} = {
  en_US: 'English',
};
