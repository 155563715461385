import React from 'react';
import {
  StyleProp,
  TouchableOpacityProps as RNTouchableOpacityProps,
  ViewStyle,
} from 'react-native';
import {TouchableOpacity as RNGHTouchableOpacity} from 'react-native-gesture-handler';

/**
 * 如果在modal或dialog中,或者点击会触发moadl或dialog,不要使用此组件,更换为native-touchable-opcaity
 */
export type TouchableOpacityProps = RNTouchableOpacityProps & {
  // 这些属性都是RNGHTouchableOpacity的Props中没导出的属性,具体查阅rne文档或源码
  onPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  onLongPress?: () => void;
  nativeID?: string;
  shouldActivateOnStart?: boolean;
  disallowInterruption?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  useNativeAnimations?: boolean;
};

const TouchableOpacity = (props: TouchableOpacityProps) => {
  const {activeOpacity, ...otherProps} = props;
  return (
    <RNGHTouchableOpacity
      activeOpacity={activeOpacity || 0.8}
      {...otherProps}
    />
  );
};

export default TouchableOpacity;
