import React from 'react';
import ErrorPage, {ErrorPageProps} from './error-page';
import i18n from '@/i18n';
import Text from '../text';

const NoData = ({...otherProps}: Omit<ErrorPageProps, 'img'>) => {
  return (
    <ErrorPage
      {...otherProps}
      img={require('@components/assets/imgs/empty.webp')}>
      <Text accent size="medium">
        {i18n.t('error-pages.no-data.label.title')}
      </Text>
    </ErrorPage>
  );
};

export default NoData;
