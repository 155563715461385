import React from 'react';
import {Dialog, LinearProgress} from '@rneui/themed';
import Text from '@basicComponents/text';
import theme from '@/style';
import {View} from 'react-native';
import globalStore from '@/services/global.state';

const style = {
  backgroundColor: '#0000',
  shadowColor: '#0000',
};

/** 这是一个加载页 */
const Splash = () => {
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    const sub = globalStore.globalLoading.subscribe(bool => setLoading(bool));
    return () => {
      setLoading(false);
      sub?.unsubscribe();
    };
  }, []);
  return (
    <Dialog isVisible={loading} backdropStyle={[style]} overlayStyle={[style]}>
      <View style={[theme.fill.fill, theme.flex.col]}>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Dialog.Loading />
          <Text main textAlign="center" size="large">
            Loading...
          </Text>
        </View>
        <LinearProgress />
      </View>
    </Dialog>
  );
};

export default Splash;
