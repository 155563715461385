import React from 'react';
import {ImageBackground, View, Image} from 'react-native';
import Text from '@basicComponents/text';
import {goTo, http} from '@/utils';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {RefreshControl, FlatList} from 'react-native-gesture-handler';
import TouchableOpacity from '@basicComponents/touchable-opacity';

type KeralaItem = {
  issueNo: string;
  lotteryType: string;
  drawDate: string | Date | number;
};

export default () => {
  const [list, setList] = React.useState<KeralaItem[]>([]);
  const [pageNo, setPageNo] = React.useState(1);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const getList = (_pageNo = pageNo, isRefresh = false) => {
    if (isRefresh) {
      setRefreshing(true);
    } else {
      globalStore.globalLoading.next(true);
    }
    http
      .post<any, KeralaItem[]>('/app/lottery/type/list', {
        type: 0,
        pageNo: _pageNo,
      })
      .then(res => {
        if (_pageNo === 1) {
          setList(res);
        } else {
          setList([...list, ...res]);
        }
      })
      .finally(() => {
        if (isRefresh) {
          setRefreshing(false);
        } else {
          globalStore.globalLoading.next(false);
        }
      });
  };
  React.useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cardWidth = globalStore.screenWidth - theme.paddingSize.l * 2;
  const cardHeight = (cardWidth * 68) / 351;
  const cardLeftWidth = (cardWidth * 120) / 351;
  const cardRightWidth = (cardWidth * 231) / 351;

  return (
    <View style={[theme.fill.fill, theme.padding.l]}>
      <FlatList
        data={list}
        style={[theme.fill.fill]}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setPageNo(1);
              getList(1, true);
            }}
          />
        }
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          setPageNo(pageNo + 1);
          getList(pageNo + 1);
        }}
        renderItem={({item, index}) => {
          const drawDate = new Date(item.drawDate);
          const hour = drawDate.getHours();
          const min = drawDate.getMinutes();
          const dateStr = `${drawDate.getDate()}-${
            drawDate.getMonth() + 1
          }-${drawDate.getFullYear()} ${hour > 12 ? hour - 12 : hour}:${
            min > 9 ? min : '0' + min
          } ${hour >= 12 ? 'PM' : 'AM'}`;
          return (
            <TouchableOpacity
              key={index}
              style={[theme.flex.row, theme.margin.btml, {height: cardHeight}]}
              onPress={() => {
                goTo('OrganicKeralaDetail', {
                  issueNo: item.issueNo,
                  drawDate: dateStr,
                });
              }}>
              <ImageBackground
                style={[
                  theme.fill.fillH,
                  theme.flex.centerByRow,
                  {width: cardLeftWidth},
                ]}
                source={require('./imgs/kerala-card-left.webp')}>
                <View style={[theme.padding.xxl]}>
                  <Text size="large" main blod>
                    {item.issueNo}
                  </Text>
                </View>
              </ImageBackground>
              <ImageBackground
                style={[
                  theme.fill.fillH,
                  theme.flex.centerByRow,
                  {width: cardRightWidth},
                ]}
                source={require('./imgs/kerala-card-right.webp')}>
                <View
                  style={[
                    theme.padding.xxl,
                    theme.flex.row,
                    theme.flex.centerByCol,
                  ]}>
                  <View style={[theme.flex.flex1]}>
                    <Text size="medium" main blod>
                      {item.lotteryType}
                    </Text>
                    <Text>{dateStr}</Text>
                  </View>
                  <Image
                    style={[theme.icon.m, {transform: [{rotate: '180deg'}]}]}
                    source={require('@components/assets/icons/back.webp')}
                  />
                </View>
              </ImageBackground>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};
