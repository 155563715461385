import React, {useCallback, useRef, useState} from 'react';
import {
  // BackHandler,
  Dimensions,
  FlatList,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  BannerInfo,
  BigWinnerItem,
  GameItem,
  GameList,
  postBannerInfo,
  postGameList,
  postLuckyDay,
  postNotifications,
  postWinnerList,
} from './casino.service';
import theme from '@style';
import LazyImage from '@basicComponents/image';
import Text from '@basicComponents/text';
import Card, {lightLinear} from '@basicComponents/card';
import {NoMoreData} from '@basicComponents/default-page';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import i18n from '@/i18n';
import CasinoBigWin from './components/casino-bigwin';
import globalStore from '@/services/global.state';
import {goTo, navigateGame, setDataForSettled} from '@/utils';
import {useFocusEffect} from '@react-navigation/native';
import {useFlatListAutoPlay} from '../hooks/autoplay.hooks';
import CasinoBanner from './casino-banner';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {FadeInView} from '@basicComponents/animations';
// import {noticeCheckOut} from '@/services/global.service';
const messageIcon = require('./assets/message.webp');
const gameIcon = require('./assets/game.webp');

const Casino = () => {
  const [gameList, setGameList] = useState<GameList>([]);
  const [bannerList, setBannerList] = useState<BannerInfo[]>([]);
  const [bigWinnerList, setBigWinnerList] = useState<BigWinnerItem[]>([]);
  const [messageList, setMessageList] = useState<string[]>([]);
  const [messageCycle, setMessageCycle] = useState<number>(1);
  const first = useRef(true);
  const getList = useCallback((showloading = true) => {
    showloading && globalStore.globalLoading.next(true);
    /* const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        noticeCheckOut();
      }
    }); */
    Promise.allSettled([
      postGameList(),
      postWinnerList(),
      postNotifications(),
      postBannerInfo(),
    ])
      .then(([game, bigwin, notify, banners]) => {
        setDataForSettled(setGameList, game);
        setDataForSettled(setBigWinnerList, bigwin);
        if (notify.status === 'fulfilled') {
          setMessageList([...notify.value, notify.value[0]]);
          setMessageCycle(notify.value.length);
        }
        setDataForSettled(setBannerList, banners);
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
        setRefreshing(false);
      });

    /* const exitApp = () => {
      BackHandler.exitApp();
      return true;
    };
    globalStore.isAndroid &&
      BackHandler.addEventListener('hardwareBackPress', exitApp);
    return () => {
      sub.unsubscribe();
      globalStore.isAndroid &&
        BackHandler.removeEventListener('hardwareBackPress', exitApp);
    }; */
  }, []);
  const handleInit = useCallback(() => {
    getList(first.current);
    first.current = false;
  }, [getList]);
  useFocusEffect(handleInit);

  const gameCardWidth =
    (Dimensions.get('window').width -
      2 * theme.paddingSize.l -
      theme.paddingSize.s) /
    2;

  const {flatListRef: messageRef} = useFlatListAutoPlay(messageList, {
    canPlay: true,
    overListLength: 1,
    timeout: 3000,
    row: false,
    cycle: messageCycle,
  });

  const toGame = (game: GameItem) => {
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    globalStore.globalLoading.next(true);
    postLuckyDay(game.gameCode, game.gameName)
      .then(() => {
        navigateGame(game.gameName, game.gameLink);
      })
      .finally(() => globalStore.globalLoading.next(false));
  };
  const [refreshing, setRefreshing] = useState<boolean>(false);
  return (
    <FadeInView>
      <View style={[theme.fill.fill, theme.background.lightGrey]}>
        <DetailNavTitle title={i18n.t('home.tab.casino')} />
        <ScrollView
          style={[theme.flex.flex1]}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                getList(false);
              }}
            />
          }>
          <CasinoBanner bannerList={bannerList} />
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.margin.lrl,
              theme.padding.lrl,
              theme.padding.tbs,
              theme.background.white,
              theme.borderRadius.m,
            ]}>
            <LazyImage
              imageUrl={messageIcon}
              width={theme.iconSize.m}
              height={theme.iconSize.m}
              occupancy={'transparent'}
            />
            <FlatList
              data={messageList}
              ref={messageRef}
              scrollEnabled={false}
              style={[{height: theme.iconSize.m}]}
              renderItem={({item, index}) => (
                <Text
                  fontSize={theme.fontSize.m}
                  key={index}
                  style={[theme.font.second, theme.margin.lefts]}>
                  {item}
                </Text>
              )}
            />
          </View>
          <CasinoBigWin bigWinnerList={bigWinnerList} />

          <View style={[theme.margin.topl]}>
            <Card
              radius={theme.borderRadiusSize.m}
              backgroundColor={theme.backgroundColor.lightGrey}>
              <Card.Title
                icon={{
                  url: gameIcon,
                  width: theme.iconSize.m,
                  height: theme.iconSize.m,
                }}
                title="Games Lobby"
                style={[styles.cardTitle]}
                linear={lightLinear}
              />
              <View
                style={[
                  theme.padding.lrl,
                  theme.flex.row,
                  theme.flex.wrap,
                  theme.flex.between,
                ]}>
                {gameList.map((game, index) => (
                  <View style={[theme.margin.btms]} key={index}>
                    <TouchableOpacity onPress={() => toGame(game)}>
                      <Card
                        key={index}
                        radius={theme.borderRadiusSize.m}
                        backgroundColor={theme.basicColor.white}>
                        <Card.Image
                          imageUrl={game.gameImg}
                          width={gameCardWidth}
                          height={(gameCardWidth / 172) * 114}
                        />
                        <View
                          style={[
                            theme.padding.lrs,
                            styles.smallCardContent,
                            theme.flex.col,
                            theme.flex.alignStart,
                            theme.flex.centerByRow,
                          ]}>
                          <Text
                            fontSize={theme.fontSize.s}
                            blod
                            style={[theme.font.second]}>
                            {game.gameName}
                          </Text>
                          <Text
                            fontSize={theme.fontSize.xs}
                            style={[theme.font.secAccent]}>
                            {game.remark || '-'}
                          </Text>
                        </View>
                      </Card>
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </Card>
          </View>
          <NoMoreData />
        </ScrollView>
      </View>
    </FadeInView>
  );
};

const styles = StyleSheet.create({
  cardTitle: {
    height: 42,
  },
  smallCardContent: {
    height: 40,
  },
});

export default Casino;
