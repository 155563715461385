import theme from '@style';
import Text from '@basicComponents/text';
import React from 'react';
import {Animated, KeyboardAvoidingView, StatusBar, View} from 'react-native';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import i18n from '@i18n';
import {goBack, goTo} from '@/utils';
import LinearGradient from '@basicComponents/linear-gradient';
import {ScrollView} from 'react-native-gesture-handler';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {passwordLogin, userLogin} from './login.service';
import {styles} from './login.style';
import CodeInput from './components/code-input';
import PhoneInput from './components/phone-input';
import NavTitle from '@basicComponents/nav-title';
import AccountTip from './components/account-tip';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {setScratchAuth} from '@/services/global.service';

const Login = (props: NavigatorScreenProps) => {
  /** 直接返回的目标页面,避免原页面加载就需要token */
  const backPage =
    ((props.route.params as BasicObject)?.backPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作,这个是用来传递给注册页面的,登录页面成功直接返回  */
  const sucessPage =
    ((props.route.params as BasicObject)?.sucessPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPageParams =
    ((props.route.params as BasicObject)?.sucessPageParams as BasicObject) ||
    null;
  const [swicthIndex, setIndex] = React.useState(0);
  const switchBgLeftValue = React.useRef(new Animated.Value(0)).current;
  const updateSwitchBgLeftValue = () => {
    requestAnimationFrame(() => {
      Animated.timing(switchBgLeftValue, {
        toValue: swicthIndex === 0 ? 0 : globalStore.screenWidth / 2 - 26,
        duration: 200,
        useNativeDriver: true,
      }).start();
    });
  };
  const [userPhone, setUserPhone] = React.useState('');
  const [userPhoneCode, setUserPhoneCode] = React.useState('+91');
  const [OTPCode, setOTPCode] = React.useState('');
  const [userPassword, setUserPassword] = React.useState('');
  const setValueOrCode = (value: string) => {
    swicthIndex === 0 ? setOTPCode(value) : setUserPassword(value);
  };
  React.useEffect(() => {
    const setTimeUpdateSwitch = () =>
      requestAnimationFrame(updateSwitchBgLeftValue);
    if (globalStore.isWeb) {
      window.addEventListener('resize', setTimeUpdateSwitch);
    }
    globalStore.removeItem('scratchToken');
    globalStore.removeItem('scratchUrl');
    globalStore.token = null;
    return () => {
      if (globalStore.isWeb) {
        window.removeEventListener('resize', setTimeUpdateSwitch);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const forgotBoxAnimation = React.useRef(new Animated.Value(0)).current;
  const forgotBoxOpcity = forgotBoxAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });
  const forgotTrans = forgotBoxAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [(theme.paddingSize.m + 18) * -1, 0],
  });
  React.useEffect(() => {
    updateSwitchBgLeftValue();
    setUserPassword('');
    setOTPCode('');
    requestAnimationFrame(() => {
      // TODO 忘记密码
      // Animated.timing(forgotBoxAnimation, {
      //   toValue: swicthIndex,
      //   duration: 300,
      //   useNativeDriver: true,
      // }).start();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swicthIndex]);
  React.useEffect(() => {
    globalStore.fullScreen && StatusBar.setBarStyle('dark-content');
  }, []);
  return (
    <KeyboardAvoidingView
      behavior="height"
      style={[theme.fill.fill, theme.flex.col, theme.background.white]}>
      <NavTitle
        onClose={() => {
          if (backPage) {
            goTo(backPage);
          } else {
            goBack();
          }
        }}
        title={i18n.t('login.label.login')}
      />
      <ScrollView
        style={[{padding: theme.paddingSize.l * 2}]}
        keyboardShouldPersistTaps="always">
        <View style={[theme.flex.row, theme.position.rel, theme.margin.btmm]}>
          <Animated.View
            style={[
              styles.switchBgStyle,
              {
                transform: [
                  {
                    translateX: switchBgLeftValue,
                  },
                ],
              },
            ]}>
            <LinearGradient
              style={[theme.fill.fill]}
              start={{x: 1, y: 1}}
              end={{x: 1, y: 0}}
              colors={['#F9F0FF', '#fff']}
            />
          </Animated.View>
          {['login.label.otp-login', 'login.label.password-login'].map(
            (v, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => setIndex(i)}
                containerStyle={[
                  theme.flex.flex1,
                  theme.flex.center,
                  theme.padding.tbxxl,
                  theme.padding.lrl,
                ]}>
                <Text style={[theme.font.fm, theme.font.bold, theme.font.main]}>
                  {i18n.t(v)}
                </Text>
              </TouchableOpacity>
            ),
          )}
        </View>
        <PhoneInput
          userPhone={userPhone}
          setUserPhone={setUserPhone}
          userPhoneCode={userPhoneCode}
          setUserPhoneCode={setUserPhoneCode}
        />
        <View style={styles.interval} />
        <CodeInput
          setValueOrCode={setValueOrCode}
          swicthIndex={swicthIndex}
          userPhone={userPhone}
          userPhoneCode={userPhoneCode}
          OTPCode={OTPCode}
          userPassword={userPassword}
        />
        <Animated.View
          style={[
            {
              opacity: forgotBoxOpcity,
              transform: [{scaleY: forgotBoxOpcity}],
            },
            theme.overflow.hidden,
          ]}>
          <TouchableOpacity style={[theme.margin.topm, theme.padding.topm]}>
            <Text
              size="medium"
              textAlign="center"
              color={theme.basicColor.primary}>
              {i18n.t('login.tip.forgot')}
            </Text>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View
          style={[
            {
              transform: [{translateY: forgotTrans}],
            },
          ]}>
          <View
            style={{
              marginVertical: theme.paddingSize.m * 2,
            }}>
            <Button
              buttonStyle={styles.loginButton}
              onPress={() => {
                globalStore.globalLoading.next(true);
                (swicthIndex === 0
                  ? userLogin(
                      (globalStore.disabledPhoneCode ? '' : userPhoneCode) +
                        userPhone,
                      OTPCode,
                    )
                  : passwordLogin(
                      (globalStore.disabledPhoneCode ? '' : userPhoneCode) +
                        userPhone,
                      userPassword,
                    )
                )
                  .then(res => {
                    globalStore.triggerAdjustEvent('account_login');
                    globalStore.token = res;
                    setScratchAuth(
                      () => {
                        goBack();
                      },
                      () => {
                        globalStore.globalLoading.next(false);
                      },
                    );
                  })
                  .catch(() => {
                    globalStore.globalLoading.next(false);
                  });
              }}
              disabledStyle={{
                backgroundColor: theme.basicColor.primary + '4c', // 4c是16进制的30%
              }}
              disabled={
                __DEV__
                  ? false
                  : swicthIndex === 1
                  ? userPassword.length < 6
                  : OTPCode.length !== 6
              }>
              <View style={[theme.padding.tbxxs]}>
                <Text color={theme.basicColor.white} size="large" blod>
                  {i18n.t('login.label.login')}
                </Text>
              </View>
            </Button>
          </View>
          <AccountTip
            tip="login.tip.new-account"
            linkTip="login.tip.sing-in"
            onPressLink={() => {
              const data: BasicObject = {};
              if (backPage) {
                data.backPage = backPage;
              }
              if (sucessPage) {
                data.sucessPage = sucessPage;
              }
              if (sucessPageParams) {
                data.sucessPageParams = sucessPageParams;
              }
              goTo('SingUp', data);
            }}
          />
        </Animated.View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default Login;
