import config from '@/utils/env.config';
import React, {useState} from 'react';
import {useWebView} from '../hooks/webview.hooks';
import theme from '@style';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {View} from 'react-native';
import {BasicObject} from '@/types';
import {goTo} from '@/utils';
import Spin from '@basicComponents/spin';

const Result = () => {
  const [title, setTitle] = useState<string>('Result');
  const [back, setBack] = useState<number>(config.reactH5Url ? 1 : 0);
  const backToFirstPage = () => {
    goBack();
  };
  const [pageLoading, setPageLoading] = React.useState(true);
  const handleMessage = (data: string | BasicObject) => {
    if (data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (data === 'back:show') {
      setBack(1);
      return;
    }
    if (data === 'back:show:first') {
      setBack(2);
      return;
    }
    if (data === 'back:hide') {
      setBack(0);
      return;
    }
    if (data.startsWith?.('scratch:')) {
      goTo('Scratch', {
        path: data.substring('scratch:'.length),
      });
    }
  };
  const urlPrefix = `${config.reactH5Url}/result`;
  const {render, goBack} = useWebView({
    urlPrefix,
    onMessage: handleMessage,
  });
  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      {title && (
        <DetailNavTitle
          title={title}
          hideServer={!!back}
          hideAmount={!!config.reactH5Url}
          serverRight
          onBack={
            back === 1
              ? () => goBack()
              : back === 2
              ? () => backToFirstPage()
              : undefined
          }
        />
      )}
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        {render}
      </Spin>
    </View>
  );
};

export default Result;
