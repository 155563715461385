import theme from '@style';
import {useBottomSheet} from '@basicComponents/modal';
import {TouchableOpacity, View} from 'react-native';
import Text from '@basicComponents/text';
import i18n from '@i18n';
import LazyImage from '@basicComponents/image';
import React, {useState} from 'react';
import globalStore from '@services/global.state';
const iconSelected = require('@components/assets/icons/language/selected.webp');
const iconClose = require('@components/assets/icons/language/close.webp');
const {borderRadiusSize, padding, font, flex, background, margin} = theme;

interface LanguageItem {
  name: string;
  locale: string;
}

type LanguageItemList = LanguageItem[];

export function useLanguageModal() {
  const [locale, setLocale] = useState('en_US');
  const languageItems: LanguageItemList = [
    {
      name: i18n.t('me.language.english'),
      locale: 'en_US',
    },
  ];

  const handleToggle = (_locale: string) => {
    globalStore.lang = _locale;
    i18n.locale = _locale;
    setLocale(_locale);
    hide();
  };
  const {renderModal, show, hide} = useBottomSheet(
    <View style={[padding.s, flex.col]}>
      <View style={[flex.row, flex.between, flex.centerByCol]}>
        <Text style={[font.bold, font.fm, font.main]}>
          {i18n.t('me.language.switch')}
        </Text>
        <TouchableOpacity activeOpacity={1} onPress={() => hide()}>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={iconClose}
            width={24}
            height={24}
          />
        </TouchableOpacity>
      </View>
      <View style={[flex.col, margin.topl]}>
        {languageItems.map((lang, index) => (
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => handleToggle(lang.locale)}
            key={index}>
            <View
              style={[
                padding.l,
                flex.row,
                flex.between,
                flex.centerByCol,
                locale === lang.locale ? background.lightGrey : {},
              ]}>
              <Text style={[font.second, font.fm]}>{lang.name}</Text>
              {locale === lang.locale ? (
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={iconSelected}
                  width={12}
                  height={12}
                />
              ) : (
                <View />
              )}
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>,
    {
      topBorderRadius: borderRadiusSize.m,
      backDropClose: true,
    },
  );

  return {renderModal, show};
}
