import React from 'react';
import {Image, View} from 'react-native';
import Text from '@basicComponents/text';
import {http} from '@/utils';
import theme from '@/style';
import {FlatList} from 'react-native-gesture-handler';
import globalStore from '@/services/global.state';

type MatkaItem = {
  backImgWeb: string;
  closeAnk: string;
  closeDp: string;
  closeNum: string;
  closeSp: string;
  closeTime: string;
  closeTp: string;
  createTime: 1694889000000;
  fullSangam: string;
  hsaNum: string;
  hsbNum: string;
  issueNo: string;
  jodi: string;
  lotteryName: string;
  openAnk: string;
  openDp: string;
  openNum: string;
  openSp: string;
  openTime: string;
  openTp: string;
};

const NumberText = ({text}: {text: string}) => (
  <Text
    size="medium"
    style={[
      text !== '-' ? {backgroundColor: theme.basicColor.dark} : null,
      theme.margin.leftxxs,
      text !== '-' ? theme.icon.m : null,
    ]}
    textAlign="center"
    color={theme.basicColor.white}>
    {text}
  </Text>
);

const cardWidth = globalStore.screenWidth / 3 - theme.paddingSize.l * 2;
const cardHeight = cardWidth * 0.81;

const cardImgWidth = globalStore.screenWidth - theme.paddingSize.l * 2;
const cardImgHeight = (cardImgWidth * 168) / 351;

const Card = ({
  value,
  title,
  type,
  margin,
}: {
  value: string;
  title: string;
  type?: string;
  margin?: boolean;
}) => (
  <View
    style={[
      value ? theme.border.primary : theme.border.secAccent,
      theme.borderRadius.m,
      theme.overflow.hidden,
      theme.background.white,
      margin ? theme.margin.lrl : null,
      theme.margin.btml,
      {
        width: cardWidth,
        height: cardHeight,
      },
    ]}>
    <View style={[theme.flex.flex1, theme.flex.center, theme.padding.xs]}>
      <Text
        blod
        color={value ? theme.fontColor.main : theme.fontColor.secAccent}
        size="medium"
        textAlign="center">
        {title}
      </Text>
      <Text color={value ? theme.fontColor.second : theme.fontColor.secAccent}>
        {type}
      </Text>
    </View>
    <Text
      style={[
        {
          backgroundColor: value
            ? theme.basicColor.primary
            : theme.fontColor.secAccent,
        },
      ]}
      color={theme.basicColor.white}
      textAlign="center"
      blod
      size="medium">
      {value || '-'}
    </Text>
  </View>
);

export default () => {
  const [list, setList] = React.useState<MatkaItem[]>([]);
  const [pageNo, setPageNo] = React.useState(1);
  const getList = (_pageNo = pageNo) => {
    globalStore.globalLoading.next(true);
    http
      .post<any, MatkaItem[]>('/app/matka/result/list', {
        pageNo: _pageNo,
        isEnd: 0,
        matkaId: 0,
      })
      .then(res => {
        if (_pageNo === 1) {
          setList(res);
        } else {
          setList([...list, ...res]);
        }
        console.log(list);
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
      });
  };
  React.useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const img = require('./imgs/satta-bg.webp');

  return (
    <View style={[theme.fill.fill]}>
      <FlatList
        data={list}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          setPageNo(pageNo + 1);
          getList(pageNo + 1);
        }}
        style={[theme.fill.fill, theme.padding.lrl, theme.padding.btml]}
        renderItem={({item, index}) => {
          const createTime = new Date(item.createTime);
          const hour = createTime.getHours();
          const min = createTime.getMinutes();
          const dateStr = `${createTime.getDate()}-${
            createTime.getMonth() + 1
          }-${createTime.getFullYear()} ${hour > 12 ? hour - 12 : hour}:${
            min > 9 ? min : '0' + min
          } ${hour >= 12 ? 'PM' : 'AM'}`;
          return (
            <View
              key={index}
              style={[
                theme.borderRadius.m,
                theme.background.white,
                theme.overflow.hidden,
                theme.margin.topl,
                theme.padding.l,
                theme.position.rel,
              ]}>
              <Image
                source={img}
                style={[
                  {height: cardImgHeight, width: cardImgWidth},
                  theme.position.abs,
                ]}
                resizeMode="contain"
              />
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                <Text blod color={theme.basicColor.white} size="large">
                  {item.lotteryName}
                </Text>
                <View style={[theme.flex.alignEnd, theme.flex.flex1]}>
                  <Text color={theme.basicColor.white}>NO.{item.issueNo}</Text>
                  <Text color={theme.basicColor.white}>{dateStr}</Text>
                </View>
              </View>
              <View style={[theme.flex.center]}>
                <View
                  style={[
                    theme.borderRadius.l,
                    theme.padding.tbs,
                    theme.flex.row,
                    theme.padding.lrxl,
                    theme.margin.tbl,
                    theme.flex.centerByCol,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {backgroundColor: 'rgba(0,0,0,0.5)'},
                  ]}>
                  <Text size="medium" color={theme.basicColor.white}>
                    PANEL:
                  </Text>
                  <NumberText text={item.hsaNum[0] || '*'} />
                  <NumberText text={item.hsaNum[1] || '*'} />
                  <NumberText text={item.hsaNum[2] || '*'} />
                  <NumberText text={item.hsaNum[3] || '-'} />
                  <NumberText text={item.hsaNum[4] || '*'} />
                  <NumberText text={item.hsbNum[0] || '*'} />
                  <NumberText text={item.hsbNum[1] || '-'} />
                  <NumberText text={item.hsbNum[2] || '*'} />
                  <NumberText text={item.hsbNum[3] || '*'} />
                  <NumberText text={item.hsbNum[4] || '*'} />
                </View>
              </View>
              <View
                style={[theme.flex.row, theme.fill.fillW, theme.margin.btml]}>
                <Text second>OPEN </Text>
                <Text main>{item.openTime}</Text>
                <View style={[theme.flex.flex1]} />
                <Text second>CLOSE </Text>
                <Text main>{item.closeTime}</Text>
              </View>
              <View style={[theme.flex.row, theme.flex.wrap]}>
                <Card title="Ank" type="Open" value={item.openAnk} />
                <Card title="Ank" margin type="Close" value={item.closeAnk} />
                <Card title="Jodi" value={item.jodi} />
                <Card
                  title="Single Patti(SP)"
                  type="Open"
                  value={item.openSp}
                />
                <Card
                  title="Single Patti(SP)"
                  margin
                  type="Close"
                  value={item.closeSp}
                />
                <Card
                  title="Double Patti(DP)"
                  type="Open"
                  value={item.openDp}
                />
                <Card
                  title="Double Patti(DP)"
                  type="Close"
                  value={item.closeDp}
                />
                <Card
                  title="Triple Patti(TP)"
                  margin
                  type="Open"
                  value={item.openTp}
                />
                <Card
                  title="Triple Patti(TP)"
                  type="Close"
                  value={item.closeTp}
                />
                <Card
                  title="Haf Sangam A(HSA)"
                  type="Open"
                  value={item.hsaNum}
                />
                <Card
                  title="Haf Sangam B(HSB)"
                  margin
                  type="Close"
                  value={item.hsbNum}
                />
                <Card title="Full Sangam" value={item.fullSangam} />
              </View>
            </View>
          );
        }}
      />
    </View>
  );
};
