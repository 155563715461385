import {
  View,
  ScrollView,
  Dimensions,
  StyleSheet,
  Platform,
  NativeScrollEvent,
  NativeSyntheticEvent,
  LayoutChangeEvent,
  // BackHandler,
  RefreshControl,
} from 'react-native';
import TouchableOpacity, {
  NativeTouchableOpacity,
} from '@basicComponents/touchable-opacity';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import theme from '@style';
import {designToDp, goTo, navigate, navigateTo, toPriceStr} from '@utils';
import Button from '@basicComponents/button';
import Text from '@basicComponents/text';
import {
  createVipBadge,
  getVipRender,
  maxVipLevel,
  useVipProgress,
} from '@businessComponents/vip';
import i18n from '@i18n';
import LinearGradient from '@basicComponents/linear-gradient';
import {
  MeListItem,
  commissionIcon,
  customerServiceIcon,
  languagesIcon,
  moneyIcon,
  notificationsIcon,
  passwordIcon,
  resultHistoryIcon,
  transactionsIcon,
  updateIcon,
} from '@businessComponents/list-item';
// import Tag from '@basicComponents/tag';
import {postUnloginVipInfo, postVipInfo} from './me.service';
import {IUserInfo, postUserInfo} from '@services/global.service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useConfirm, ToastType} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useBottomTabBarHeight} from '@react-navigation/bottom-tabs';
import {SafeAny} from '@types';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import globalStore from '@/services/global.state';
import {toLogin, topBackground} from './me.variable';
import MeHeader from './me-header';
import MeAmount from './me-amount';
import MeUser from './me-user';
import {FadeInView} from '@basicComponents/animations';
import {NoMoreData} from '@/components/basic/default-page';
import config from '@/utils/env.config';
import {useVersionModal} from '@/common-pages/hooks/versionmodal.hooks';
import {getVersion} from 'react-native-device-info';
const {
  flex,
  overflow,
  padding,
  paddingSize,
  font,
  margin,
  borderRadius,
  background,
  fontSize,
} = theme;

const topClipHeight = globalStore.isWeb ? 50 : 44;

interface VipInfo {
  level: number;
  diff: number; // 差多少值结束
  nextValue: number; // 该等级结束值
}

const notYetWarning = {
  type: ToastType.warning,
  message: i18n.t('warning.unopen'),
};

/** TODO 单个文件过大,需要拆解 */
const Me = () => {
  const navigation = useNavigation<SafeAny>();

  const systemWidth = globalStore.screenWidth;
  const [login, setLogin] = useState<boolean>(false);
  const [vip, setVip] = useState<VipInfo>({
    level: 0,
    diff: 200,
    nextValue: 200,
  });
  const currentVipOption = useMemo(() => getVipRender(vip.level), [vip.level]);
  const nextVipBadge = createVipBadge(vip.level + 1);
  const topBlockWidth = systemWidth - 4 * paddingSize.m;
  const renderProgress = useVipProgress({
    currentBadge: currentVipOption.badge,
    nextBadge: nextVipBadge,
    current: vip.nextValue - vip.diff,
    total: vip.nextValue,
  });
  const [user, setUser] = useState<IUserInfo>();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const {versionModal, handleUpdate} = useVersionModal(false);
  const [showNoMenu] = useState<boolean>(false);
  const bottomTabbarHeight = useBottomTabBarHeight();

  const [userAreaY, setUserAreaY] = useState<number>(0);
  const [float, setFloat] = useState<boolean>(false);
  const firstFocus = useRef(true);

  const refresh = useCallback(async (showloading = true) => {
    const token = await AsyncStorage.getItem('token');
    showloading && globalStore.globalLoading.next(true);
    try {
      if (!token) {
        setLogin(false);
        const vipList = await postUnloginVipInfo();
        const _vip = vipList?.find(x => x.level === 1);
        if (_vip) {
          setVip({
            level: 0,
            diff: _vip.amount,
            nextValue: _vip.amount,
          });
        }
      } else {
        setLogin(true);
        const results = await Promise.allSettled([
          postUserInfo(),
          postVipInfo(),
        ]);
        const [userInfo, vipInfo] = results;
        if (userInfo.status === 'fulfilled') {
          setUser(userInfo.value);
        }
        if (vipInfo.status === 'fulfilled') {
          const {value: list} = vipInfo;
          for (let i = 0; i < list.length; i++) {
            if (list[i].diff > 0) {
              const current = list[i];
              setVip({
                level: current.level,
                diff: current.diff,
                nextValue: current.amount,
              });
              break;
            }
          }
        }
      }
    } finally {
      globalStore.globalLoading.next(false);
      setRefreshing(false);
    }
  }, []);
  const onFocusEffect = useCallback(() => {
    refresh(firstFocus.current);
    firstFocus.current = false;

    /* if (globalStore.isAndroid) {
      const exitApp = () => {
        BackHandler.exitApp();
        return true;
      };
      BackHandler.addEventListener('hardwareBackPress', exitApp);
      return () => {
        BackHandler.removeEventListener('hardwareBackPress', exitApp);
      };
    } */
  }, [refresh]);
  useFocusEffect(onFocusEffect);

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const scrollY = e.nativeEvent.contentOffset.y;
    setFloat(scrollY > userAreaY);
  };

  const handleMoneyLayout = (e: LayoutChangeEvent) => {
    setUserAreaY(e.nativeEvent.layout.y);
  };

  const handleRefresh = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    refresh();
  };

  const toVip = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('WebView', {
      path: '/vip',
      header: true,
    });
    // postMessage('router:/pages/vip/vip');
  };

  const toRecharge = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转recharge
    goTo('WebView', {
      path: '/recharge',
      header: true,
    });
  };

  const toWithdraw = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转withdraw
    goTo('WebView', {
      path: '/withdraw',
      header: true,
    });
    // postMessage('router:/pages/withdrawal/withDraw');
  };

  const toTransfer = () => {
    // 跳转transfer
    // console.log('目前没有跳转Transfer的');
    globalStore.globalTotal.next(notYetWarning);
  };

  const toResults = () => {
    goTo('Result');
    // postMessage('tabbar:/pages/results/results');
  };

  const toCommission = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转commission
    goTo('WebView', {
      path: '/commission',
      header: true,
    });
    // postMessage('router:/pages/commission/myCommission');
    // console.log('目前没有跳转commission的，需要从anna里面搬过来');
  };

  const toTransactions = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('WebView', {
      path: '/transactions',
      header: true,
    });
  };

  const toMyBets = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转mybets
    navigation.navigate('WebView', {
      path: '/bets?title=1',
      header: true,
    });
  };

  const toNotify = () => {
    // 跳转notify
    // console.log('目前没有notify，且先不上');
    globalStore.globalTotal.next(notYetWarning);
  };

  const toCustomer = () => {
    // 跳转customer
    navigateTo(config.customerServerUrl);
  };

  const toLanguage = () => {
    languageShow();
  };

  const toUpdate = () => {
    // 更新
    // console.log('安卓版才需要，此处不需要');
    handleUpdate();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      globalStore.token = null;
      globalStore.triggerAdjustEvent('account_logout');
      toLogin();
    });
  };

  const toSetPassword = () => {
    if (!login) {
      toLogin();
      return;
    }
    navigate('SetPassword');
  };

  const toAgency = () => {
    if (!login) {
      toLogin();
      return;
    }
    navigation.navigate('WebView', {
      path: '/proxy',
    });
  };

  const handleUser = () => {
    if (!login) {
      toLogin();
      return;
    }
    if (showNoMenu) {
      globalStore.globalTotal.next(notYetWarning);
    }
  };
  const [refreshing, setRefreshing] = useState<boolean>(false);
  return (
    <FadeInView>
      {/* TODO 这里的滚动方案需要优化,以及文件过大需要拆分 */}
      <ScrollView
        style={{height: Dimensions.get('window').height - bottomTabbarHeight}}
        onScroll={handleScroll}
        scrollEventThrottle={16}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              refresh(false);
            }}
          />
        }
        stickyHeaderIndices={[0]}>
        <MeHeader
          user={user}
          login={login}
          float={float}
          onUser={handleUser}
          showNoMenu={showNoMenu}
        />
        <View
          style={[
            flex.col,
            flex.end,
            {height: (systemWidth / 375) * (372 - topClipHeight)},
          ]}>
          <LazyImageBackground
            occupancy={'transparent'}
            imageUrl={topBackground}
            width={systemWidth}
            height={(systemWidth / 375) * 372}
            style={[padding.lrm, styles.topBg, overflow.hidden, padding.btmm]}>
            <View style={[padding.m]}>
              {/* 用户块 */}
              <MeUser
                login={login}
                user={user}
                currentVipOption={currentVipOption}
                onUser={handleUser}
                showNoMenu={showNoMenu}
              />
              {/* 钱包区域 */}
              <MeAmount
                login={login}
                onLayout={handleMoneyLayout}
                topBlockWidth={topBlockWidth}
                user={user}
                onRecharge={toRecharge}
                onWithdraw={toWithdraw}
                onRefresh={handleRefresh}
              />
              {/* VIP区域，直接使用当前的TouchableOpacity组件会导致安卓的vip图标被遮挡，故直接使用原生的 */}
              <NativeTouchableOpacity activeOpacity={0.8} onPress={toVip}>
                {currentVipOption.backgroundFn(
                  <View style={[margin.lrm]}>
                    <View
                      style={[
                        flex.row,
                        flex.alignEnd,
                        {marginTop: -paddingSize.l},
                        margin.leftm,
                      ]}>
                      <View style={[margin.rightxs]}>
                        {currentVipOption.sign('small')}
                      </View>
                      {/* 小进度条 */}
                      <View
                        style={[
                          margin.btmxxs,
                          theme.flex.flex1,
                          theme.flex.row,
                          theme.flex.alignEnd,
                        ]}>
                        {renderProgress}
                      </View>

                      <View />
                    </View>
                    {vip.level >= maxVipLevel ? (
                      <View />
                    ) : (
                      <View
                        style={[
                          flex.row,
                          flex.between,
                          flex.centerByCol,
                          padding.lrm,
                          padding.tbxxs,
                        ]}>
                        <View style={[flex.row]}>
                          <Text
                            numberOfLines={2}
                            style={[font.main, styles.opacity]}
                            fontSize={fontSize.xs}>
                            {i18n.t('me.vip.recharge')}{' '}
                            <Text blod>
                              {toPriceStr(vip.nextValue, {fixed: 0})}
                            </Text>{' '}
                            {i18n.t('me.vip.move')} VIP{vip.level + 1}
                          </Text>
                        </View>
                        <Button
                          type="linear-primary"
                          size="xsmall"
                          titleBold
                          title={'Deposit'}
                          onPress={toVip}
                        />
                      </View>
                    )}
                  </View>,
                  topBlockWidth,
                )}
              </NativeTouchableOpacity>
            </View>
          </LazyImageBackground>
        </View>
        {/* 下半区域 */}
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 0, y: 1}}
          colors={['#fff', '#fff0']}
          style={[padding.lrl, background.lightGrey]}>
          <View style={[flex.row, styles.bottomNav, flex.around]}>
            <TouchableOpacity
              containerStyle={[flex.center, styles.bottomNavItem]}
              onPress={toRecharge}>
              <View style={[flex.center]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/me/recharge.webp')}
                  width={designToDp(28)}
                  height={designToDp(28)}
                />
                <Text style={[font.fs, font.second, margin.tops]}>
                  {i18n.t('me.bottom.recharge')}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              containerStyle={[flex.center, styles.bottomNavItem]}
              onPress={toWithdraw}>
              <View style={[flex.center]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/me/withdraw.webp')}
                  width={designToDp(28)}
                  height={designToDp(28)}
                />
                <Text style={[font.fs, font.second, margin.tops]}>
                  {i18n.t('me.bottom.withdraw')}
                </Text>
              </View>
            </TouchableOpacity>
            {showNoMenu && (
              <NativeTouchableOpacity
                style={[flex.center, styles.bottomNavItem]}
                onPress={toTransfer}>
                <View style={[flex.center]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={require('@assets/icons/me/transfer.webp')}
                    width={designToDp(28)}
                    height={designToDp(28)}
                  />
                  <Text style={[font.fs, font.second, margin.tops]}>
                    {i18n.t('me.bottom.transfer')}
                  </Text>
                </View>
              </NativeTouchableOpacity>
            )}
          </View>
        </LinearGradient>
        <View style={[padding.lrl, padding.btmxxl, background.lightGrey]}>
          {/* 列表区域 */}
          <View
            style={[
              background.white,
              padding.lrm,
              padding.tbl,
              borderRadius.m,
              overflow.hidden,
              margin.btml,
            ]}>
            <MeListItem
              icon={require('@assets/icons/me/proxy.webp')}
              title={i18n.t('me.bottom.proxy')}
              rightContent={
                <View
                  style={[theme.flex.center, theme.padding.lrxs, styles.new]}>
                  <Text fontSize={8} style={[theme.font.white]}>
                    {i18n.t('me.new')}
                  </Text>
                </View>
              }
              onPress={toAgency}
            />
            <MeListItem
              icon={resultHistoryIcon}
              title={i18n.t('me.bottom.resultHistory')}
              onPress={toResults}
            />

            <MeListItem
              icon={moneyIcon}
              title={i18n.t('me.bottom.myBets')}
              onPress={toMyBets}
            />

            <MeListItem
              icon={transactionsIcon}
              title={i18n.t('me.bottom.myTransactions')}
              onPress={toTransactions}
            />
            <MeListItem
              icon={commissionIcon}
              title={i18n.t('me.bottom.commission')}
              onPress={toCommission}
            />
          </View>
          <View
            style={[
              background.white,
              padding.lrm,
              padding.tbl,
              borderRadius.m,
              overflow.hidden,
              margin.btml,
            ]}>
            {showNoMenu && (
              <MeListItem
                icon={notificationsIcon}
                title={i18n.t('me.bottom.notify')}
                rightContent={<>{/* <Tag content={2} /> */}</>}
                onPress={toNotify}
              />
            )}

            <MeListItem
              icon={passwordIcon}
              title={i18n.t('me.bottom.password')}
              onPress={toSetPassword}
            />

            <MeListItem
              icon={languagesIcon}
              title={i18n.t('me.bottom.lang')}
              onPress={toLanguage}
            />
            <MeListItem
              icon={customerServiceIcon}
              title={i18n.t('me.bottom.customer')}
              onPress={toCustomer}
            />
            {Platform.OS === 'android' && (
              <MeListItem
                icon={updateIcon}
                title={i18n.t('me.bottom.update')}
                rightContent={
                  <Text style={[font.secAccent, font.fs]}>{getVersion()}</Text>
                }
                onPress={toUpdate}
              />
            )}
          </View>
          {login && (
            <TouchableOpacity onPress={doLogout}>
              <View
                style={[
                  background.white,
                  padding.lrm,
                  padding.tbl,
                  borderRadius.m,
                  overflow.hidden,
                  margin.btmxxl,
                ]}>
                <Text style={[font.second, font.fm, font.bold, font.center]}>
                  {i18n.t('me.bottom.logout')}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          <NoMoreData text="" />
        </View>
      </ScrollView>
      {renderConfirmModal}
      {renderLanguageModal}
      {versionModal.renderModal}
    </FadeInView>
  );
};

const styles = StyleSheet.create({
  topBg: {
    /** 此处由于裁剪高度是根据宽度按比例换算的，所以这边也需要按照比例换算paddingTop */
    paddingTop: designToDp(topClipHeight) + paddingSize.m,
  },
  opacity: {
    opacity: 0.5,
  },
  lightOpacity: {
    opacity: 0.8,
  },
  bottomNav: {
    height: designToDp(80),
  },
  bottomNavItem: {
    width: designToDp(104),
  },
  new: {
    backgroundColor: '#FF4D4D',
    borderRadius: 20,
    paddingVertical: 2,
  },
});

export default Me;
