import globalStore from '@/services/global.state';
import {getUrlParams, goTo} from '@utils';
import {SafeAny} from '@types';

declare var window: any;

export function postMessage(data: SafeAny) {
  if (globalStore.isWeb && window.top) {
    window.top.postMessage(
      data,
      getUrlParams(window.location.href).topwindowurl,
    );
  }
}

export function navigateTo(url: string) {
  if (globalStore.isWeb) {
    if (window.top) {
      window.top.location.href = url;
    } else {
      window.location.href = url;
    }
  } else {
    goTo('WebView', {
      nav: false,
      originUrl: url,
    });
  }
}

export function navigateGame(gameName: string, link: string) {
  const homeUrlSpell = globalStore.isWeb
    ? `homeurl=${window.location.origin}/index/casino`
    : '';

  const url = `${link}${
    link.indexOf('?') === link.length - 1
      ? ''
      : link.endsWith('cert=')
      ? ''
      : '&cert='
  }${globalStore.token}&${homeUrlSpell}`;

  goTo('WebView', {
    header: true,
    hideAmount: true,
    headerTitle: gameName,
    originUrl: url,
  });
}
