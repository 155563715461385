import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import i18n from '@/i18n';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {SafeAny} from '@/types';
import Text from '@basicComponents/text';
import React, {useEffect, useState} from 'react';
import {
  View,
  Image,
  StyleSheet,
  FlatList,
  ListRenderItemInfo,
} from 'react-native';
import {defaultHeader, smallWinnerIcon} from './casino.variable';
import LinearGradient from '@basicComponents/linear-gradient';
import {useRoute} from '@react-navigation/native';
import {
  BigWinnerTopItem,
  postBigWinnerInfo,
  postLuckyDay,
} from './casino.service';
import {goTo, navigateGame, toPriceStr} from '@utils';
import Button from '@basicComponents/button';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import BigWin from './components/bigwin';
import NoData from '@/components/basic/error-pages/no-data';

const CasinoDetail = () => {
  const {params} = useRoute<SafeAny>();
  const {
    gameName = '',
    gameCode = '',
    jackpotAmount = '0',
  } = params as {
    gameName: string;
    gameCode: string;
    jackpotAmount: string;
  };
  const [imageUrl, setImageUrl] = useState<string>();
  const [smallImg, setSmallImg] = useState<string>();
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [bigWinInfo, setBigWinInfo] = useState<BigWinnerTopItem[]>([]);
  const innerStyles = StyleSheet.create({
    image: {
      width: globalStore.screenWidth,
      height: imageHeight,
      top: 0,
      left: 0,
      zIndex: -1,
    },
  });
  const bottomImageSize = (theme.imageSize.m * globalStore.screenWidth) / 375;

  useEffect(() => {
    globalStore.globalLoading.next(true);
    postBigWinnerInfo(gameCode, gameName, jackpotAmount)
      .then(info => {
        setImageUrl(info[0].backImg);
        setSmallImg(info[0].bigWinsSmallImg);
        setBigWinInfo(info);
        Image.getSize(info[0].backImg, (width, height) => {
          setImageHeight((globalStore.screenWidth / width) * height);
        });
      })
      .finally(() => globalStore.globalLoading.next(false));
  }, [gameCode, gameName, jackpotAmount]);

  const toGame = () => {
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    globalStore.globalLoading.next(true);
    postLuckyDay(gameCode, gameName)
      .then(() => {
        /* navigateTo(
          `${bigWinInfo[0].gameLink}${
            bigWinInfo[0].gameLink.indexOf('?') ===
            bigWinInfo[0].gameLink.length - 1
              ? ''
              : bigWinInfo[0].gameLink.endsWith('cert=')
              ? ''
              : '&cert='
          }${globalStore.token}&${homeUrlSpell}`,
        ); */
        navigateGame(gameName, bigWinInfo[0].gameLink);
      })
      .finally(() => globalStore.globalLoading.next(false));
  };

  const renderItem = ({item, index}: ListRenderItemInfo<BigWinnerTopItem>) => {
    const headerSize = (40 * globalStore.screenWidth) / 375;
    const smallWinnerIconSize = (14 * globalStore.screenWidth) / 375;
    return (
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.padding.tbs,
          styles.bottomBorder,
        ]}
        key={index}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <LazyImageBackground
            imageUrl={item.bigWinnerDto.avatar || defaultHeader}
            width={headerSize}
            height={headerSize}
            radius={headerSize / 2}
            style={[theme.position.rel]}>
            <View style={[styles.winIcon, theme.position.abs]}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={smallWinnerIcon}
                width={smallWinnerIconSize}
                height={smallWinnerIconSize}
              />
            </View>
          </LazyImageBackground>
          <Text fontSize={theme.fontSize.m} style={[theme.margin.leftl]}>
            {item.bigWinnerDto.userName}
          </Text>
        </View>
        <View style={[theme.flex.col, theme.flex.alignEnd]}>
          <LinearGradient
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={['#FF5C00', '#FFB800']}
            style={[styles.winPrize, theme.padding.lrxs, theme.flex.center]}>
            <Text fontFamily="fontDin" fontSize={8} fontWeight="900">
              {i18n.t('casino.winPrize')}
            </Text>
          </LinearGradient>
          <Text
            main
            fontFamily="fontDin"
            fontSize={theme.fontSize.l}
            fontWeight="900">
            {toPriceStr(+item.bigWinnerDto.bonus, {
              currency: globalStore.currency,
              thousands: true,
              fixed: 0,
            })}
          </Text>
        </View>
      </View>
    );
  };
  return (
    <View
      style={[
        theme.flex.col,
        {width: globalStore.screenWidth, height: globalStore.screenHeight},
        theme.position.rel,
        theme.background.lightGrey,
      ]}>
      {imageUrl && (
        <Image
          source={{uri: imageUrl}}
          style={[innerStyles.image, theme.position.abs]}
          resizeMode="cover"
        />
      )}
      <View style={[styles.back, theme.position.abs]}>
        <TouchableOpacity onPress={() => goTo('Casino')}>
          <LazyImage
            occupancy="#0000"
            imageUrl={require('./assets/chevron-left.png')}
            width={theme.iconSize.l}
            height={theme.iconSize.l}
          />
        </TouchableOpacity>
      </View>

      <View
        style={[
          styles.bigwinIcon,
          theme.position.abs,
          theme.fill.fillW,
          theme.flex.start,
        ]}>
        <LazyImageBackground
          style={[theme.margin.leftl, theme.flex.center]}
          imageUrl={require('./assets/bigwin.png')}
          occupancy={'transparent'}
          width={199}
          height={42}>
          <Text
            fontSize={24}
            fontFamily="fontDin"
            fontWeight="900"
            style={[theme.font.white]}>
            {toPriceStr(+jackpotAmount, {
              fixed: 0,
              currency: globalStore.currency,
              thousands: true,
            })}
          </Text>
        </LazyImageBackground>
      </View>
      <View style={[styles.players, theme.flex.flex1, theme.padding.lrl]}>
        <View
          style={[
            styles.playersContent,
            theme.flex.flex1,
            theme.flex.col,
            theme.overflow.hidden,
          ]}>
          <View
            style={[
              theme.flex.center,
              theme.padding.topxxl,
              theme.padding.btmm,
              theme.background.white,
            ]}>
            <Text blod fontSize={theme.fontSize.m} main>
              {i18n.t('casino.detail.title')}
            </Text>
          </View>
          <FlatList
            style={[theme.flex.flex1]}
            contentContainerStyle={[
              theme.padding.lrxxl,
              theme.padding.btmm,
              styles.playersList,
              theme.margin.btml,
              theme.background.white,
            ]}
            scrollEnabled
            renderItem={renderItem}
            data={bigWinInfo}
            ListEmptyComponent={
              <View style={[theme.padding.xxl]}>
                <NoData />
              </View>
            }
          />
        </View>
      </View>
      <View
        style={[
          theme.fill.fillW,
          theme.padding.l,
          theme.background.white,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
        ]}>
        <View
          style={[theme.padding.lrl, theme.flex.row, theme.flex.centerByCol]}>
          <View style={[theme.overflow.hidden, theme.borderRadius.xs]}>
            <LazyImage
              imageUrl={
                smallImg ||
                'https://lottery-india.oss-ap-south-1.aliyuncs.com/iGaming/luckyloto-s.png'
              }
              width={bottomImageSize}
              height={bottomImageSize}
            />
          </View>
          <View
            style={[
              theme.flex.col,
              theme.flex.alignStart,
              theme.margin.leftxxl,
            ]}>
            <Text
              blod
              main
              fontSize={theme.fontSize.l}
              style={[theme.margin.btmxs]}>
              {gameName}
            </Text>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <BigWin />
              <Text
                main
                fontWeight="900"
                fontFamily="fontDin"
                fontSize={theme.fontSize.l}
                style={[theme.margin.leftxxs]}>
                {toPriceStr(+jackpotAmount, {
                  fixed: 0,
                  currency: globalStore.currency,
                  thousands: true,
                })}
              </Text>
            </View>
          </View>
        </View>
        <View style={[theme.margin.rightl]}>
          <Button
            size="large"
            titleBold
            title={i18n.t('casino.playnow')}
            onPress={() => toGame()}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  back: {
    top: theme.paddingSize.xl,
    left: theme.paddingSize.xxl,
  },
  bigwinIcon: {
    top: 111,
  },
  bottomArea: {
    left: 0,
    bottom: 0,
  },
  winIcon: {
    right: 0,
    bottom: 0,
  },
  bigwin: {
    paddingVertical: theme.paddingSize.xxs / 2,
    backgroundColor: theme.basicColor.dark,
    borderTopLeftRadius: theme.borderRadiusSize.xs,
    borderBottomRightRadius: theme.borderRadiusSize.xs,
  },
  players: {
    marginTop: 196,
  },
  playersContent: {
    borderTopLeftRadius: theme.borderRadiusSize.m,
    borderTopRightRadius: theme.borderRadiusSize.m,
  },
  playersList: {
    borderBottomLeftRadius: theme.borderRadiusSize.m,
    borderBottomRightRadius: theme.borderRadiusSize.m,
  },
  winPrize: {
    borderTopLeftRadius: theme.borderRadiusSize.xs,
    borderBottomRightRadius: theme.borderRadiusSize.xs,
    height: 12,
    marginBottom: theme.paddingSize.xxs / 2,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#D8E0EA',
  },
});

export default CasinoDetail;
