import React from 'react';
import {ImageURISource, TouchableOpacity, View, Image} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@style';
import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {NavigatorScreenProps} from '@types';
import Kerala from './kerala';
import Matka from './matka';
import Search from './search';

const Tab = createBottomTabNavigator();

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img: ImageURISource;
  activeImg: ImageURISource;
}[] = [
  {
    name: 'Kerala',
    link: 'organic/kerala',
    component: Kerala,
    img: require('./icons/ticket.png'),
    activeImg: require('./icons/ticket-active.png'),
  },
  {
    name: 'Matka',
    link: 'organic/matka',
    component: Matka,
    img: require('./icons/list.png'),
    activeImg: require('./icons/list-active.png'),
  },
  {
    name: 'Search',
    link: 'organic/search',
    component: Search,
    img: require('./icons/search.png'),
    activeImg: require('./icons/search-active.png'),
  },
];

const CusTab = (props: BottomTabBarProps) => {
  return (
    <View style={[theme.flex.row, theme.flex.between, theme.background.white]}>
      {props.state.routes.map((route, index) => {
        const {options} = props.descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? (options.tabBarLabel as string)
            : options.title !== undefined
            ? options.title
            : route.name;
        const isFocused = props.state.index === index;
        const onPress = () => {
          const event = props.navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });
          if (!isFocused && !event.defaultPrevented) {
            props.navigation.navigate(route.name);
          }
        };
        const onLongPress = () =>
          props.navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        return (
          <TouchableOpacity
            key={label}
            activeOpacity={0.8}
            accessibilityRole="button"
            accessibilityState={isFocused ? {selected: true} : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[theme.flex.center, theme.flex.flex1, theme.padding.tbs]}>
            <Image
              style={theme.icon.l}
              source={
                isFocused
                  ? mainPageList[index].activeImg
                  : mainPageList[index].img
              }
            />
            <Text
              blod={isFocused}
              style={{
                color: isFocused
                  ? theme.basicColor.primary
                  : theme.basicColor.dark,
              }}>
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const OrganicNav = () => {
  return (
    <Tab.Navigator
      initialRouteName="Result"
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => <CusTab {...props} />}
      // screenOptions={{headerShown: false}}
    >
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: v.name,
          }}
        />
      ))}
    </Tab.Navigator>
  );
};

export default OrganicNav;
