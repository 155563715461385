import {useModal} from '@basicComponents/modal';
import Text from '@basicComponents/text';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import i18n from '@/i18n';
import theme from '@style';
import {Image} from '@rneui/themed';

import {VersionInfo, checkVersion} from '@/app.service';
import React, {useEffect} from 'react';
import {BackHandler, Linking, View} from 'react-native';
import globalStore from '@/services/global.state';

export function useVersionModal(autoCheckVersion?: boolean) {
  const [versionInfo, setVersionInfo] = React.useState<VersionInfo | null>(
    null,
  );
  const versionModal = useModal(
    <View style={[theme.flex.col, theme.flex.centerByCol]}>
      {versionInfo && (
        <Image
          source={{uri: versionInfo.popBack}}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            width: '100%',
            aspectRatio: 1,
            resizeMode: 'contain',
          }}
        />
      )}
      <View style={theme.padding.l}>
        <Text size="medium">{versionInfo?.levelContent}</Text>
        <View style={[theme.margin.topl, theme.flex.row, theme.fill.fillW]}>
          <View style={theme.flex.flex1} />
          <View>
            <NativeTouchableOpacity
              onPress={() => {
                Linking.openURL(versionInfo!.downUrl);
              }}>
              <Text
                size="large"
                textAlign="right"
                main
                color={theme.basicColor.primary}>
                {i18n.t('label.download')}
              </Text>
            </NativeTouchableOpacity>
            <NativeTouchableOpacity
              onPress={() => {
                if (versionInfo?.levelUp === 3) {
                  BackHandler.exitApp();
                } else {
                  versionModal.hide();
                }
              }}
              style={[theme.margin.topm]}>
              <Text size="large" secAccent textAlign="right">
                {i18n.t('label.cancel')}
              </Text>
            </NativeTouchableOpacity>
          </View>
        </View>
      </View>
    </View>,
    {
      backDropClose: false,
      overlayStyle: {
        padding: 0,
        ...theme.borderRadius.m,
        ...theme.overflow.hidden,
        ...theme.margin.lrxxl,
      },
    },
  );

  useEffect(() => {
    if (globalStore.isAndroid && autoCheckVersion) {
      checkVersion().then(res => {
        if (res) {
          setVersionInfo(res);
          versionModal.show();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCheckVersion]);

  const handleUpdate = () => {
    globalStore.globalLoading.next(true);
    checkVersion()
      .then(res => {
        if (res) {
          setVersionInfo(res);
          versionModal.show();
        } else {
          globalStore.globalTotal.next({
            type: 'success',
            message: i18n.t('no-update'),
          });
        }
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
      });
  };

  return {
    versionModal,
    handleUpdate,
  };
}
