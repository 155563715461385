import React from 'react';
import {View, StyleProp, TextStyle, ViewStyle} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@style';

const {background, flex, font, backgroundColor: backgroundColorVar} = theme;

export enum TagType {
  badge = 'badge',
}

export interface TagProps {
  type?: TagType;
  content?: string | number;
  /** 当type='badge'时的size设计值 */
  badgeSize?: number;
  fontSize?: number;
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
}

const Tag: React.FC<TagProps> = props => {
  const {
    type = TagType.badge,
    badgeSize = 20,
    content,
    fontSize = 10,
    backgroundColor = backgroundColorVar.main,
    style,
  } = props;
  const resultBadgeSize = badgeSize;

  const tagStyleMap: Record<
    TagType,
    {
      textStyle?: StyleProp<TextStyle>;
      style?: StyleProp<ViewStyle>;
    }
  > = {
    [TagType.badge]: {
      textStyle: [font.xs, font.white],
      style: [
        flex.center,
        background.main,
        {
          width: resultBadgeSize,
          height: resultBadgeSize,
          borderRadius: resultBadgeSize / 2,
          backgroundColor: backgroundColor,
        },
        style,
      ],
    },
  };

  const tagStyle = tagStyleMap[type];
  return (
    <View style={[tagStyle.style]}>
      <Text blod style={[tagStyle.textStyle]} fontSize={fontSize}>
        {content}
      </Text>
    </View>
  );
};

export default Tag;
