import React from 'react';
import {Platform, StyleSheet, TextStyle} from 'react-native';
import {Text as RNEText, TextProps as RNETextProps} from '@rneui/themed';
import theme from '@style';
import {BasicObject, Size} from '@types';

interface TextProps extends RNETextProps {
  fontFamily?: 'fontDin' | 'fontAnybody' | 'fontInter' | 'fontInterBold';
  /** 优先级低于fontSize 'large': 16 | 'default': 12 | 'medium': 14 | 'small': 12 */
  size?: Size;
  /** 是否粗体,传入true会设置字体为fontInterBold,如果同时指定了其他字体,会将字重设置为700 */
  blod?: boolean;
  fontSize?: TextStyle['fontSize'];
  fontWeight?: TextStyle['fontWeight'];
  /** 是否使用主题色,默认使用二级字体色 */
  primary?: boolean;
  /** 是否使用主字体色 */
  main?: boolean;
  /** 是否使用二级体色 */
  second?: boolean;
  /** 是否使用三级体色 */
  accent?: boolean;
  /** 是否使用四级体色 */
  secAccent?: boolean;
  /** 字间距,默认-0.3 */
  letterSpacing?: number;
  textAlign?: 'left' | 'right' | 'center';
  color?: TextStyle['color'] | null;
}
const Text: React.FC<TextProps> = props => {
  const {
    style,
    fontSize,
    size = 'small',
    fontWeight,
    fontFamily,
    color,
    blod,
    primary = false,
    main = false,
    second = false,
    accent = false,
    secAccent = false,
    letterSpacing = -0.3,
    textAlign = 'left',
    ...otherProps
  } = props;
  let _fontSize: number;
  if (fontSize) {
    _fontSize = fontSize;
  } else {
    switch (size) {
      case 'large':
        _fontSize = theme.fontSize.l;
        break;
      case 'medium':
        _fontSize = theme.fontSize.m;
        break;
      case 'default':
      case 'small':
        _fontSize = theme.fontSize.s;
        break;
      default:
        _fontSize = size;
    }
  }
  let _fontWeight: typeof fontWeight, _fontFamily: typeof fontFamily;
  if (blod) {
    _fontFamily = fontFamily || 'fontInterBold';
    if (_fontFamily !== 'fontInterBold') {
      _fontWeight = '700';
    } else {
      _fontWeight = 'normal';
    }
  } else {
    _fontFamily = fontFamily || 'fontInter';
    _fontWeight = 'normal';
  }
  const midStyle: TextStyle & BasicObject = {
    fontSize: _fontSize,
    fontWeight: _fontWeight,
    letterSpacing,
    textAlign,
    color:
      color ||
      (primary
        ? theme.basicColor.primary
        : main
        ? theme.fontColor.main
        : second
        ? theme.fontColor.second
        : accent
        ? theme.fontColor.accent
        : secAccent
        ? theme.fontColor.secAccent
        : theme.fontColor.second),
  };
  if (_fontSize! < 12 && Platform.OS === 'web') {
    midStyle.fontSize = 12;
    midStyle.zoom = fontSize! / 12;
  }
  const mergedStyles = StyleSheet.flatten([
    theme.font[_fontFamily],
    midStyle,
    style,
  ]);
  return <RNEText style={mergedStyles} {...otherProps} />;
};

export default Text;
