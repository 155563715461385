import theme from '@style';
import {ToastType, useModal, useToast} from '@basicComponents/modal';
import {
  FlatList,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
} from 'react-native';
import WebTouchableOpacity, {
  NativeTouchableOpacity,
} from '@basicComponents/touchable-opacity';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Animated} from 'react-native';
import Text from '@basicComponents/text';
import {goTo, toPriceStr} from '@utils';
import LinearGradient from '@basicComponents/linear-gradient';
import {Tab} from '@rneui/themed';
import globalStore from '@services/global.state';
import {
  postRankingList,
  postSpinOrderCreate,
  postSpinOrderList,
} from '../home.service';
import {SafeAny} from '@/types';
import {
  RankingItem,
  RankingList,
  SpinOrderItem,
  SpinOrderList,
} from '../home.type';
import i18n from '@/i18n';
import Sound from '@basicComponents/sound';
import {useGetModal} from './getmodal.hooks';
import {
  ITEM_HEIGHT,
  baseIcon,
  buttonBlueIcon,
  buttonGreenIcon,
  closeIcon,
  copperIcon,
  moneyIcon,
  needleHeight,
  needleIcon,
  needleWidth,
  openAudio,
  resultIcon,
  rotateAudio,
  spinWrapIcon,
  styles,
  titleIcon,
} from './luckyspin.style';

const TouchableOpacity = globalStore.isWeb
  ? WebTouchableOpacity
  : NativeTouchableOpacity;

export function useLuckySpinModal(drawImage?: string) {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rankingList, setRankingList] = useState<RankingList>([]);
  const [spinOrderList, setSpinOrderList] = useState<SpinOrderList>([]);
  const animateTiming = useRef<Animated.CompositeAnimation>();
  const [animating, setAnimating] = useState<boolean>(false);
  const rotateSound = useRef<Sound>();
  const openSound = useRef<Sound>();
  const refresh = () => {
    postRankingList().then(list => setRankingList(list));
    if (globalStore.token) {
      postSpinOrderList({pageNo: 1, pageSize: 1000}).then(list =>
        setSpinOrderList(list),
      );
    }

    globalStore.updateAmount.next();
  };
  const {renderModal: renderGetModal, show: getModalShow} =
    useGetModal(refresh);
  const {renderModal: renderToast, show: toastShow} = useToast();
  const rankIndex = useRef<number>(0);
  const rankTiming = useRef<NodeJS.Timeout>();
  const rankRef = useRef<FlatList>(null);

  const degreeAnim = useRef<Animated.Value>(new Animated.Value(0)).current;
  const degreeResult = degreeAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });
  useEffect(() => {
    if (!showModal) {
      return;
    }
    rotateSound.current = new Sound(rotateAudio);
    openSound.current = new Sound(openAudio);
    refresh();

    return () => {
      rankIndex.current = 0;
      if (animateTiming.current) {
        animateTiming.current.stop();
        animateTiming.current = undefined;
      }

      if (rotateSound.current) {
        rotateSound.current.release();
        rotateSound.current = undefined;
      }

      if (openSound.current) {
        openSound.current.release();
        openSound.current = undefined;
      }

      setAnimating(false);
    };
  }, [showModal]);
  const updateRankIndex = useCallback(() => {
    rankTiming.current = setTimeout(() => {
      if (
        rankRef.current &&
        rankingList.length > 4 &&
        rankIndex.current < rankingList.length
      ) {
        rankIndex.current++;
        rankRef.current.scrollToIndex({
          index: rankIndex.current,
          animated: true,
        });
        updateRankIndex();
      }
    }, 2000);
  }, [rankingList.length]);
  useEffect(() => {
    if (
      showModal &&
      tabIndex === 0 &&
      rankingList.length > 4 &&
      rankIndex.current < rankingList.length
    ) {
      updateRankIndex();
    } else {
      if (rankTiming.current) {
        clearTimeout(rankTiming.current);
      }
    }

    return () => {
      if (rankTiming.current) {
        clearTimeout(rankTiming.current);
      }
    };
  }, [rankingList.length, showModal, tabIndex, updateRankIndex]);
  const handleRankingScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const y = e.nativeEvent.contentOffset.y;
    const nowIndex = Math.floor(y / ITEM_HEIGHT);
    rankIndex.current = nowIndex;
  };
  const handleChange = (value: number) => {
    setTabIndex(value);
  };
  const handlePrize = async (count: number) => {
    if (animating) {
      // 如果在动画中，创建失效
      return;
    }
    try {
      const result = await postSpinOrderCreate(count);
      if (count === 1) {
        let rotate = (result.prizeIndex + 0.5) / 8 + 10;
        animateTiming.current = Animated.timing(degreeAnim, {
          duration: 5000,
          toValue: rotate,
          useNativeDriver: true,
        });
        setAnimating(true);
        rotateSound.current?.play();
        requestAnimationFrame(() => {
          animateTiming.current?.start(() => {
            rotateSound.current?.stop();
            getModalShow(+result.prizeAmount);
            openSound.current?.play();
            globalStore.updateAmount.next();
            setAnimating(false);
            degreeAnim.setValue(rotate - Math.floor(rotate));
          });
        });
      } else {
        getModalShow(+result.prizeAmount);
        openSound.current?.play();
        globalStore.updateAmount.next();
      }
    } catch (e) {
      if ((e as SafeAny).data.code === -1) {
        handleHide();
        return;
      }
      toastShow({
        type: ToastType.warning,
        message: (e as SafeAny).data.msg,
      });
    }
  };
  const renderItem = ({
    item,
  }: ListRenderItemInfo<RankingItem | SpinOrderItem>) => {
    // console.log(item);
    return (
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.resultItem,
          theme.padding.lrxl,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          {isRankItem(item) ? (
            <Text style={[theme.font.fs, theme.font.white]}>
              {item.userPhone}
            </Text>
          ) : (
            <>
              <Text style={[theme.font.fs, styles.buy, theme.margin.rightxxs]}>
                buy
              </Text>
              <Text style={[theme.font.fs, theme.font.white]}>
                {toPriceStr(+item.betAmount)}
              </Text>
            </>
          )}
        </View>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text
            style={[theme.font.fs, theme.font.white, theme.margin.rightxxs]}>
            {i18n.t('home.luckyspin.won')}
          </Text>
          <Text style={[theme.font.fs, styles.won]}>
            {toPriceStr(+item.prizeAmount)}
          </Text>
        </View>
      </View>
    );
  };
  const {renderModal, show, hide} = useModal(
    <View style={[theme.position.rel, theme.flex.col, theme.flex.centerByCol]}>
      <TouchableOpacity
        style={
          globalStore.isWeb ? null : [theme.position.abs, styles.closeButton]
        }
        containerStyle={[theme.position.abs, styles.closeButton]}
        onPress={() => handleHide()}>
        <LazyImage
          occupancy={'transparent'}
          imageUrl={closeIcon}
          width={40}
          height={40}
        />
      </TouchableOpacity>
      <LazyImage
        occupancy={'transparent'}
        imageUrl={titleIcon}
        width={290}
        height={66}
      />
      <LazyImageBackground
        occupancy={'transparent'}
        imageUrl={spinWrapIcon}
        width={310}
        height={313}
        style={[styles.drawImageWrap]}>
        {drawImage && (
          <Animated.Image
            source={{uri: drawImage}}
            style={[styles.drawImage, {transform: [{rotate: degreeResult}]}]}
            resizeMode={'stretch'}
          />
        )}
        <View style={[theme.position.abs, styles.needle]}>
          <LazyImage
            occupancy={'transparent'}
            width={needleWidth}
            height={needleHeight}
            imageUrl={needleIcon}
          />
        </View>
        <View style={[theme.position.abs, styles.copper]}>
          <LazyImageBackground
            occupancy={'transparent'}
            imageUrl={copperIcon}
            width={160}
            height={32}
            style={[theme.flex.center, theme.flex.row]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={moneyIcon}
              width={18}
              height={18}
            />
            <Text
              fontSize={theme.fontSize.m}
              fontFamily="fontDin"
              style={[theme.font.bold, theme.margin.rightm]}>
              {toPriceStr(globalStore.userAmount, {
                currency: globalStore.currency,
              })}
            </Text>
            <TouchableOpacity
              onPress={() => {
                if (animating) {
                  return;
                }
                goTo('WebView', {
                  path: '/recharge',
                  header: true,
                });
                handleHide();
              }}>
              <LinearGradient
                start={{x: 0, y: 0}}
                end={{x: 0, y: 1}}
                colors={['#f83600', '#fcc065']}
                style={[
                  theme.borderRadius.xs,
                  theme.padding.tbxxs,
                  theme.padding.lrxs,
                ]}>
                <Text fontSize={theme.fontSize.xs} style={[theme.font.white]}>
                  {i18n.t('home.luckyspin.add')}
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </LazyImageBackground>
        </View>
        <View style={[theme.position.abs, styles.base]}>
          <LazyImageBackground
            imageUrl={baseIcon}
            occupancy={'transparent'}
            width={301}
            height={95}
            style={[
              theme.flex.row,
              theme.flex.centerByRow,
              theme.flex.alignEnd,
              theme.padding.btmm,
            ]}>
            <TouchableOpacity
              onPress={() => handlePrize(1)}
              disabled={animating}>
              <LazyImageBackground
                occupancy={'transparent'}
                imageUrl={buttonGreenIcon}
                width={125}
                height={46}
                style={[theme.flex.col, theme.flex.centerByCol]}>
                <View style={[theme.flex.row, theme.flex.center]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={moneyIcon}
                    width={25}
                    height={25}
                  />
                  <Text
                    fontSize={theme.fontSize.xl}
                    style={[theme.font.bold, theme.font.white]}>
                    x10
                  </Text>
                </View>
                <Text
                  fontSize={theme.fontSize.s}
                  style={[theme.font.bold, theme.font.white]}>
                  {i18n.t('home.luckyspin.spin')}
                </Text>
              </LazyImageBackground>
            </TouchableOpacity>
            <TouchableOpacity
              style={[theme.margin.leftxl]}
              disabled={animating}
              onPress={() => handlePrize(30)}>
              <LazyImageBackground
                occupancy={'transparent'}
                imageUrl={buttonBlueIcon}
                width={125}
                height={46}
                style={[theme.flex.col, theme.flex.centerByCol]}>
                <View style={[theme.flex.row, theme.flex.center]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={moneyIcon}
                    width={25}
                    height={25}
                  />
                  <Text
                    fontSize={theme.fontSize.xl}
                    style={[theme.font.bold, theme.font.white]}>
                    x300
                  </Text>
                </View>
                <Text
                  fontSize={theme.fontSize.s}
                  style={[theme.font.bold, theme.font.white]}>
                  {i18n.t('home.luckyspin.spin')}x30
                </Text>
              </LazyImageBackground>
            </TouchableOpacity>
          </LazyImageBackground>
        </View>
      </LazyImageBackground>
      <LazyImageBackground
        occupancy={'transparent'}
        style={[styles.result, theme.flex.col, theme.flex.start]}
        imageUrl={resultIcon}
        width={261}
        height={166}>
        <Tab
          disableIndicator
          style={[styles.tabWrap, theme.margin.lrl, theme.margin.topl]}
          buttonStyle={[styles.nonepadding]}
          containerStyle={active => (active ? [styles.tabActive] : [])}
          titleStyle={active => [
            ...(active ? [styles.tabActiveText] : [styles.tabNotActiveText]),
            theme.font.fs,
            theme.font.bold,
          ]}
          value={tabIndex}
          onChange={handleChange}>
          <Tab.Item>{i18n.t('home.luckyspin.winner')}</Tab.Item>
          <Tab.Item>{i18n.t('home.luckyspin.mySpin')}</Tab.Item>
        </Tab>
        {tabIndex === 0 && (
          <FlatList
            style={[styles.resultList]}
            data={rankingList}
            initialNumToRender={4}
            ref={rankRef}
            renderItem={renderItem}
            onScroll={handleRankingScroll}
            getItemLayout={(data, index) => ({
              length: ITEM_HEIGHT,
              offset: ITEM_HEIGHT * index,
              index,
            })}
          />
        )}
        {tabIndex === 1 && (
          <FlatList
            style={[styles.resultList]}
            initialNumToRender={4}
            data={spinOrderList}
            renderItem={renderItem}
          />
        )}
      </LazyImageBackground>
      {renderGetModal}
      {renderToast}
    </View>,
    {
      backDropClose: true,
      onBackDropClose() {
        handleHide();
      },
      overlayStyle: {
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
      },
    },
  );
  const handleShow = () => {
    setShowModal(true);
    show();
  };
  const handleHide = () => {
    if (animating) {
      return;
    }
    setShowModal(false);
    hide();
  };
  return {
    renderModal,
    show: handleShow,
  };
}

function isRankItem(item: RankingItem | SpinOrderItem): item is RankingItem {
  if ((item as RankingItem).userPhone != null) {
    return true;
  }
  return false;
}
