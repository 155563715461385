// /**
//  * Sample React Native App
//  * https://github.com/facebook/react-native
//  *
//  * @format
//  */

// import globalStore from '@/services/global.state';
// import React from 'react';
// import {SafeAreaView, StyleSheet, Text, View} from 'react-native';

// import Animated, {
//   useAnimatedStyle,
//   useSharedValue,
//   useAnimatedScrollHandler,
//   withTiming,
//   interpolate,
//   interpolateColor,
//   Extrapolate,
// } from 'react-native-reanimated';

// function Demo(): JSX.Element {
//   const scrollY = useSharedValue(0);

//   const headerStyle = useAnimatedStyle(() => {
//     return {
//       backgroundColor: interpolateColor(
//         scrollY.value,
//         [0, 100],
//         ['red', 'green'],
//         'RGB',
//       ),
//       height: interpolate(scrollY.value, [0, 100], [80, 40], Extrapolate.CLAMP),
//     };
//   }, [scrollY]);

//   const headerTitleStyle = useAnimatedStyle(() => {
//     return {
//       color: interpolateColor(
//         scrollY.value,
//         [0, 100],
//         ['white', 'yellow'],
//         'RGB',
//       ),
//       fontSize: interpolate(
//         scrollY.value,
//         [0, 100],
//         [18, 10],
//         Extrapolate.CLAMP,
//       ),
//     };
//   }, [scrollY]);

//   const onScroll = useAnimatedScrollHandler(event => {
//     const {y} = event.contentOffset;
//     scrollY.value = withTiming(y, {duration: 100});
//   });

//   return (
//     <SafeAreaView>
//       <View
//         style={[
//           {
//             height: globalStore.screenHeight,
//             width: globalStore.screenWidth,
//           },
//         ]}>
//         <Animated.View style={[styles.headerView, headerStyle]}>
//           <Animated.Text style={[{fontSize: 18}, headerTitleStyle]}>
//             这是 header{' '}
//           </Animated.Text>
//         </Animated.View>

//         <Animated.ScrollView
//           style={[
//             {
//               flex: 1,
//             },
//           ]}
//           onScroll={onScroll}
//           scrollEventThrottle={16}
//           contentInsetAdjustmentBehavior="automatic">
//           {Array(100)
//             .fill('')
//             .map((_v, i) => (
//               <Text key={i}>123</Text>
//             ))}
//         </Animated.ScrollView>
//       </View>
//     </SafeAreaView>
//   );
// }

// const styles = StyleSheet.create({
//   sectionContainer: {
//     marginTop: 32,
//     paddingHorizontal: 24,
//   },
//   sectionTitle: {
//     fontSize: 24,
//     fontWeight: '600',
//   },
//   sectionDescription: {
//     marginTop: 8,
//     fontSize: 18,
//     fontWeight: '400',
//   },
//   highlight: {
//     fontWeight: '700',
//   },
//   headerView: {
//     width: '100%',
//     height: 80,
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
// });

// export default Demo;

import * as React from 'react';
import {Dimensions, Text, View} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';

function Index() {
  const width = Dimensions.get('window').width;
  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles
      style={{flex: 1}}>
      <Carousel
        loop
        width={width}
        height={width / 2}
        autoPlay={true}
        data={[...new Array(6).keys()]}
        scrollAnimationDuration={1000}
        onSnapToItem={index => console.log('current index:', index)}
        renderItem={({index}) => (
          <View
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              flex: 1,
              borderWidth: 1,
              justifyContent: 'center',
            }}>
            <Text
              // eslint-disable-next-line react-native/no-inline-styles
              style={{textAlign: 'center', fontSize: 30}}>
              {index}
            </Text>
          </View>
        )}
      />
    </View>
  );
}

export default Index;
