import {http} from '@utils';

export const sendCode = (userPhone: string) => {
  return http.post('app/sendCode', {userPhone});
};

export const userLogin = (
  userPhone: string,
  code: string,
  userInviteCode?: string,
) => {
  return http.post<null, string>('app/userLogin', {
    userPhone,
    code,
    userInviteCode,
  });
};

export const passwordLogin = (userPhone: string, password: string) => {
  return http.post<null, string>('app/password/login', {
    userPhone,
    password,
  });
};

export const updatePassword = (password: string) => {
  return http.post<null, string>('app/user/set/password', {
    password,
  });
};
