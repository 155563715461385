import globalStore from '@services/global.state';
import LazyImage from '@basicComponents/image';
import {navigateTo} from '@utils';
import React from 'react';
import {Pressable} from 'react-native';
const downloadImage = require('@/assets/imgs/home/download.webp');

const Download: React.FC = () => {
  const toDownload = () => {
    // navigateTo(
    //   'https://singam.oss-accelerate.aliyuncs.com/channel/googleh5/lottery-release.apk',
    // );
    navigateTo(
      'https://singam.oss-accelerate.aliyuncs.com/channel/google/lottery-release-v2.3.3.apk',
    );
  };
  return (
    <Pressable onPress={toDownload}>
      <LazyImage
        width={globalStore.screenWidth}
        height={(globalStore.screenWidth / 375) * 54}
        imageUrl={downloadImage}
      />
    </Pressable>
  );
};

export default Download;
