import LinearGradient from '@basicComponents/linear-gradient';
import {designToDp} from '@utils';
import theme from '@style';
import React, {ReactNode, useState} from 'react';
import {LayoutChangeEvent, StyleSheet, View} from 'react-native';
import Text from '@basicComponents/text';
import i18n from '@i18n';

const {flex, margin, font, fontSize} = theme;

export interface VipProgressInfo {
  currentBadge: ReactNode;
  nextBadge?: ReactNode;
  current: number;
  total: number;
}

export function useVipProgress({
  currentBadge,
  nextBadge,
  current,
  total,
}: VipProgressInfo) {
  const [width, setWidth] = useState<number>(0);
  const height = designToDp(4);
  const progressWidth = (width * current) / total;
  const styles = StyleSheet.create({
    progressBg: {
      width,
      height,
      borderRadius: height / 2,
      backgroundColor: '#B2AFE4',
      overflow: 'hidden',
    },
    progress: {
      width: progressWidth,
      height,
    },
    alighStratch: {alignSelf: 'stretch'},
    opacity: {
      opacity: 0.5,
    },
  });
  const handleLayout = (e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width);
  };
  return (
    <View onLayout={handleLayout} style={[flex.col, flex.flex1, margin.lrm]}>
      <View style={[flex.row, flex.between, margin.btmxxs]}>
        <View style={[flex.row, flex.centerByCol]}>
          {currentBadge}
          <Text
            style={[styles.opacity, font.main, margin.leftxxs]}
            fontSize={fontSize.xs}>
            {i18n.t('vip.currentLevel')}
          </Text>
        </View>

        {nextBadge}
      </View>
      {/* 此处shadow不好做，目前暂时换为纯色做 */}
      <View style={[styles.progressBg]}>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          colors={['#7601F7', '#8501E0']}
          style={[styles.progress]}
        />
      </View>
    </View>
  );
}
