import {BasicObject, SafeAny} from '@types';
import {useWebView} from '../hooks/webview.hooks';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {View} from 'react-native';
import React, {useEffect, useState} from 'react';
import theme from '@style';
import i18n from '@/i18n';
import config from '@utils/env.config';
import globalStore from '@/services/global.state';
import LazyImage from '@basicComponents/image';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {goTo} from '@/utils';
import Spin from '@basicComponents/spin';

type GameType = 'kerala' | 'digit' | 'color' | 'dice' | 'matka';

const gameMap: Record<
  GameType,
  {
    title: string;
    path: string;
  }
> = {
  kerala: {
    title: i18n.t('home.kerala.title'),
    // path: '/pages/kerala/kerala',
    path: '/kerala',
  },
  digit: {
    title: i18n.t('home.digit.title'),
    path: '/digit',
  },
  color: {
    title: i18n.t('home.color.title'),
    // path: '/pages/colorPrediction/colorPrediction',
    path: '/color',
  },
  dice: {
    title: i18n.t('home.dice.title'),
    path: '/dice',
  },
  matka: {
    title: i18n.t('home.matka.title'),
    // path: '/pages/sattaMatka/sattaMatka',
    path: '/satta',
  },
};

const GameWebView = (props: SafeAny) => {
  const {route} = props;
  const type: GameType = route.params.type;
  const params = route.params.params;
  const gameOption = gameMap[type || 'color'];
  const [title, setTitle] = useState<string>(gameOption.title);

  const urlPrefix = `${config.reactH5Url}${gameOption.path}${
    params ? `?${params}` : ''
  }`;
  const [ruleUrl, setRuleUrl] = useState<string>();
  const [pageLoading, setPageLoading] = React.useState(true);
  const handleMessage = (data: string | BasicObject) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (data.startsWith?.('rule:')) {
      setRuleUrl(data.substring('rule:'.length));
      return;
    }
  };
  useEffect(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next();
    }
  }, []);
  const {render, goBack} = useWebView({
    urlPrefix,
    onMessage: handleMessage,
  });
  const renderRules =
    (type === 'color' || type === 'matka') && ruleUrl ? (
      <TouchableOpacity
        onPress={() =>
          goTo('WebView', {header: true, path: ruleUrl, isReactH5: '0'})
        }>
        <LazyImage
          occupancy={'transparent'}
          imageUrl={require('@components/assets/icons/rule.png')}
          width={theme.iconSize.l}
          height={theme.iconSize.l}
        />
      </TouchableOpacity>
    ) : null;
  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      <DetailNavTitle
        title={title}
        hideServer={true}
        onBack={() => goBack()}
        rightNode={renderRules}
      />
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        {render}
      </Spin>
    </View>
  );
};

export default GameWebView;
