import LazyImage, {ImageUrlType} from '@components/basic/image';
import theme from '@style';
import {designToDp} from '@components/utils';
import React, {ReactNode} from 'react';
import {Pressable, View} from 'react-native';
import Text from '@basicComponents/text';

const {flex, font, margin, padding} = theme;

const defaultIconSize = designToDp(24);

export const commissionIcon = require('@components/assets/icons/me-list-item/commission.webp');
export const customerServiceIcon = require('@components/assets/icons/me-list-item/customer-service.webp');
export const languagesIcon = require('@components/assets/icons/me-list-item/languages.webp');
export const moneyIcon = require('@components/assets/icons/me-list-item/money.webp');
export const notificationsIcon = require('@components/assets/icons/me-list-item/notifications.webp');
export const resultHistoryIcon = require('@components/assets/icons/me-list-item/result-history.webp');
export const transactionsIcon = require('@components/assets/icons/me-list-item/transactions.webp');
export const updateIcon = require('@components/assets/icons/me-list-item/update-icon.webp');
export const passwordIcon = require('@components/assets/icons/me-list-item/password.webp');

const rightIcon = require('@components/assets/icons/me-list-item/right-icon.webp');
const rightIconSize = designToDp(14);

export interface MeListItemProps {
  icon: ImageUrlType;
  iconSize?: number;
  title: string;
  hasRightIcon?: boolean;
  rightContent?: ReactNode;
  onPress?: () => void;
}

const MeListItem: React.FC<MeListItemProps> = props => {
  const {
    icon,
    iconSize = defaultIconSize,
    title,
    rightContent = null,
    hasRightIcon = true,
    onPress,
  } = props;
  return (
    <Pressable
      onPress={onPress}
      style={[
        flex.centerByCol,
        flex.row,
        flex.between,
        padding.lrm,
        padding.tbl,
      ]}>
      <View style={[flex.centerByCol, flex.row]}>
        {icon && (
          <LazyImage
            occupancy={'transparent'}
            imageUrl={icon}
            width={iconSize}
            height={iconSize}
          />
        )}
        <Text style={[margin.leftxxl, font.fm, font.second]}>{title}</Text>
      </View>
      <View style={[flex.row, flex.centerByCol]}>
        {rightContent}
        {hasRightIcon && (
          <View style={[margin.leftl]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={rightIcon}
              width={rightIconSize}
              height={rightIconSize}
            />
          </View>
        )}
      </View>
    </Pressable>
  );
};

export default MeListItem;
