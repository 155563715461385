import {SafeAny} from '@/types';
import {indusWinHttp} from '@utils';

export interface GameItem {
  callBackLink: string;
  gameCode: string;
  gameImg: string;
  gameName: string;
  jackpotAmount: number;
  maxAmount: number;
  minAmount: number;
  payLink: string;
  status: number;
  subscriptStatus: number;
  /** 描述 */
  remark: number;
  gameLink: string;
  [k: string]: SafeAny;
}

export interface BigWinnerDto {
  avatar: string;
  bonus: number;
  userName: string;
}

export interface BigWinnerItem {
  bigWinnerDto: BigWinnerDto;
  bigWinsImg: string;
  gameCode: string;
  gameName: string;
  jackpotAmount: number;
  [k: string]: SafeAny;
}

export interface BigWinnerTopItem {
  backImg: string;
  bigWinnerDto: BigWinnerDto;
  gameLink: string;
  bigWinsSmallImg: string;
}

export interface BannerInfo {
  imgUrl: string;
  skipLinks: string;
}

export interface CasinoBaseParams {
  packageId: number;
  packageInfo: string | null;
}

export interface CasinoGameParams extends CasinoBaseParams {
  gameName: string;
  gameCode: string;
}

export type GameList = GameItem[];
export type BigWinnerList = BigWinnerItem[];

export function postGameList() {
  return indusWinHttp.post<CasinoBaseParams, GameList>('iGaming/home/gameList');
}

export function postWinnerList() {
  return indusWinHttp.post<CasinoBaseParams, BigWinnerItem[]>(
    'iGaming/home/bigWinnerList',
  );
}

export function postNotifications() {
  return indusWinHttp.post<CasinoBaseParams, string[]>(
    'iGaming/home/scrollNotificationList',
  );
}

export function postBannerInfo() {
  return indusWinHttp.post<CasinoBaseParams, BannerInfo[]>(
    'iGaming/banner/getBannersList',
  );
}

export function postBigWinnerInfo(
  gameCode: string,
  gameName: string,
  jackpotAmount: string,
) {
  return indusWinHttp.post<CasinoGameParams, BigWinnerTopItem[]>(
    'iGaming/home/bigWinnerInfo',
    {gameCode, gameName, jackpotAmount: +jackpotAmount || 0},
  );
}

export function postLuckyDay(gameCode: string, gameName: string) {
  return indusWinHttp.post<CasinoGameParams, null>('iGaming/user/luckyDay', {
    gameCode,
    gameName,
  });
}
