import React from 'react';
import Text from '@basicComponents/text';
import {View, StyleSheet} from 'react-native';
import theme from '@/style';
import {LazyImageBackground} from '@basicComponents/image';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {goTo, toPriceStr} from '@/utils';
import i18n from '@/i18n';
import globalStore from '@/services/global.state';
import {DigitListItem} from '../../home.type';
import CountDown from '../count-down';

const HomeDigitCard = ({
  item,
  marginTop,
  cardWidth,
  imageHeight,
}: {
  item: DigitListItem;
  marginTop: boolean;
  cardWidth: number;
  imageHeight: number;
}) => {
  return (
    <TouchableOpacity
      key={item.id}
      style={[
        {
          width: cardWidth,
        },
        marginTop ? theme.margin.tops : null,
        theme.flex.col,
        theme.borderRadius.s,
        theme.overflow.hidden,
        theme.background.white,
      ]}
      onPress={() => {
        goTo('GameWebView', {
          type: 'digit',
          params: `id=${item.id}&pickName=${item.pickName}&pickLogo=${item.pickBackImg}`,
        });
      }}>
      <LazyImageBackground
        height={imageHeight}
        width={cardWidth}
        imageUrl={item.pickBackImg}
        style={[
          theme.flex.col,
          theme.flex.alignStart,
          theme.flex.between,
          theme.padding.s,
        ]}>
        <Text
          fontSize={8}
          blod
          style={[styles.lotteryType, theme.padding.lrxxs, theme.font.white]}>
          {item.pickName}
        </Text>
        <View style={[theme.flex.col, theme.flex.alignStart]}>
          <Text fontSize={8} style={[theme.font.white, theme.margin.btmxxs]}>
            {i18n.t('home.digit.booking')}
          </Text>
          <CountDown
            remain={item.drawTime ? Math.round(item.drawTime / 1000) : 0}
          />
        </View>
      </LazyImageBackground>
      <View
        style={[
          theme.padding.s,
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.start,
        ]}>
        <View style={[theme.flex.row, theme.flex.alignEnd]}>
          <Text main fontSize={theme.fontSize.s} fontFamily="fontInterBold">
            {toPriceStr(item.sellAmount, {
              currency: globalStore.currency,
            })}
          </Text>
          <Text accent fontSize={9}>
            /{i18n.t('home.digit.ticket')}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  lotteryType: {
    borderRadius: 2,
    paddingVertical: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
  },
});

export default HomeDigitCard;
