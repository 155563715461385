import React, {useState} from 'react';
import {StatusBar, View} from 'react-native';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {NavigationContainer} from '@react-navigation/native';
import {SafeAreaView, SafeAreaProvider} from 'react-native-safe-area-context';
import MainNav from './main-navigation';
import theme from '@style';
import {linking, routes} from './route';
import {getUUID, getUrlParams, http, navigationRef} from '@utils';
import globalStore from './services/global.state';
import {BasicObject, SafeAny} from '@types';
import {DialogLoading} from '@basicComponents/dialog';
import {useToast} from '@basicComponents/modal';
import {getBalance} from './services/global.service';
import {takeUntil, throttleTime} from 'rxjs';
import envConfig from './utils/env.config';
import {OrganicNav} from './organic';
import {TIME_END, setLang, setToken, setVisitor} from './app.service';
import {useVersionModal} from './common-pages/hooks/versionmodal.hooks';

globalStore.visitor = getUUID();

declare var Adjust: any;
const Stack = createStackNavigator();
const ENV = process.env.NODE_ENV;
// 直接指定渠道
globalStore.asyncSetItem('hasChecked', 'Live');
globalStore.channel = 'Live';
// 清除hasChecked,调试ab面逻辑
// globalStore.asyncRemoveItem('hasChecked');

function App(): JSX.Element {
  /**
   * web直接b面
   * app端时间没到直接a面,否则进入Splash等待判断逻辑
   */
  const [indexPage, setIndexPage] = React.useState(
    globalStore.isWeb ? 'Index' : TIME_END ? 'Splash' : 'Organic',
  );
  const [globalLoading, setGlobalLoading] = React.useState(false);
  /** 是否已经完成过network检测 */
  const [netWrokChecked, setNetWrokChecked] = React.useState(false);
  const _netWrokChecked = React.useRef(false);
  const {renderModal: renderToast, show: toastShow} = useToast();
  const [screenHeight, setScreenHeight] = useState(
    globalStore.isWeb ? window.innerHeight + 'px' : '100vh',
  );

  /** android端初始化adjust */
  const initAndroidAdjust = () => {
    const AdjustModule = require('react-native-adjust');
    const _Adjust = AdjustModule.Adjust as SafeAny;
    const AdjustConfig = AdjustModule.AdjustConfig;
    const adjustConfig = new AdjustConfig(
      envConfig.appToken,
      ENV === 'production'
        ? AdjustConfig.EnvironmentProduction
        : AdjustConfig.EnvironmentSandbox,
    );
    // adjustConfig.setProcessName("com.example.myapp");
    adjustConfig.setLogLevel(AdjustConfig.LogLevelVerbose);
    _Adjust.create(adjustConfig);
    let timer = 0;
    const id = setInterval(() => {
      _Adjust.getAttribution((attribution: SafeAny) => {
        if (attribution.adid) {
          clearInterval(id);
          setVisitor(attribution.adid);
          globalStore.channel = globalStore.channel || attribution.network;
          globalStore.globalLoading.next(false);
          if (!_netWrokChecked.current) {
            // 如果标记过是否自然量,不执行,否则判断是否自然量
            const nextPage =
              attribution.network && attribution.network !== 'Organic'
                ? 'Index'
                : 'Organic';
            navigationRef.current!.reset({
              index: 0,
              routes: [{name: nextPage}],
            });
            globalStore.asyncSetItem('hasChecked', attribution.network);
          }
        }
        if (++timer > 100) {
          clearInterval(id);
          // 如果超过5s还未初始化成功,检查是否标记过
          // 未标记则进入a面,并标记为Organic
          globalStore.globalLoading.next(false);
          if (!_netWrokChecked.current) {
            globalStore.asyncSetItem('hasChecked', attribution.network);
            const nextPage =
              attribution.network && attribution.network !== 'Organic'
                ? 'Index'
                : 'Organic';
            navigationRef.current!.reset({
              index: 0,
              routes: [{name: nextPage}],
            });
          }
        }
      });
    }, 50);
    globalStore.adjustEvent
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(adjustEvent => {
        _Adjust.trackEvent(adjustEvent);
      });
    globalStore.appDistory.subscribe(() => {
      globalStore.removeItem('scratchToken');
      globalStore.removeItem('scratchUrl');
      _Adjust.componentWillUnmount();
    });
  };

  /** web端初始化adjust */
  const initWebAdjust = () => {
    Adjust.initSdk({
      appToken: envConfig.appToken,
      environment: ENV === 'production' ? 'production' : 'sandbox',
      logLevel: 'verbose',
    });
    const id = setInterval(() => {
      if (Adjust.getWebUUID()) {
        clearInterval(id);
        const attrs = Adjust.getAttribution();
        setVisitor(attrs.adid);
        globalStore.channel = globalStore.channel || attrs.network;
      }
    }, 50);
    globalStore.adjustEventH5
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(adjustEvent => {
        Adjust.trackEvent(adjustEvent);
      });
  };

  /** 全局订阅 */
  const globalSubscriptions = () => {
    globalStore.globalLoading
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(bool => setGlobalLoading(bool));
    globalStore.globalTotal
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(config => {
        config.message = config.message;
        toastShow(config);
      });
    globalStore.tokenSubject
      .pipe(takeUntil(globalStore.appDistory))
      .subscribe(token => {
        if (!token) {
          return;
        }
        globalStore.updateAmount.next();
      });
    globalStore.updateAmount
      .pipe(throttleTime(200), takeUntil(globalStore.appDistory))
      .subscribe(() => {
        getBalance().then(amount => {
          globalStore.setAmount(amount);
        });
      });
  };

  React.useEffect(() => {
    setToken();
    setLang();
    setVisitor(getUUID());
    globalSubscriptions();
    http
      .post<
        null,
        {
          eventName: string;
          eventCode: string;
        }[]
      >('app/adjustEvent/list', {appCode: envConfig.appToken})
      .then(adjustMap => {
        globalStore.adjustEventMap = adjustMap.reduce((a, b) => {
          a[b.eventName] = b.eventCode;
          return a;
        }, {} as BasicObject);
        console.log(globalStore.adjustEventMap);
      });
    if (globalStore.isAndroid) {
      globalStore.asyncGetItem('hasChecked').then(res => {
        if (res) {
          // 如果存在自然量标记且,若为自然量,跳转a面,否则跳转到b面
          setIndexPage(res === 'Organic' ? 'Organic' : 'Index');
          setNetWrokChecked(true);
          _netWrokChecked.current = true;
        } else {
          globalStore.globalLoading.next(true);
        }
        initAndroidAdjust();
      });
    } else if (globalStore.isWeb) {
      globalStore.asyncGetItem('channel').then(channel => {
        globalStore.channel = channel || getUrlParams().channel;
        if (ENV === 'development') {
          // 开发环境热更会触发代码执行,但页面不会刷新,所以这样防止重复初始化
          !Adjust.getWebUUID() && initWebAdjust();
        } else {
          initWebAdjust();
        }
        globalStore.updateDimensions();
        window.addEventListener('resize', () => {
          globalStore.updateDimensions();
          setScreenHeight(
            globalStore.isWeb ? window.innerHeight + 'px' : '100vh',
          );
        });
      });
    }
    return () => {
      globalStore.appDistory.next(true);
      globalStore.appDistory.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {versionModal} = useVersionModal(true);

  return (
    <SafeAreaProvider style={[theme.background.lightGrey]}>
      <StatusBar translucent />
      <SafeAreaView
        style={[
          globalStore.isWeb &&
            ({
              height: screenHeight,
              width: '100vw',
            } as BasicObject),
          theme.flex.col,
        ]}>
        <View style={[theme.fill.fill]}>
          <NavigationContainer ref={navigationRef} linking={linking}>
            <Stack.Navigator
              initialRouteName={indexPage}
              screenOptions={{
                headerShown: false,
                cardStyleInterpolator: globalStore.isWeb
                  ? undefined
                  : CardStyleInterpolators.forHorizontalIOS,
              }}>
              <Stack.Screen name="Index" component={MainNav} />
              <Stack.Screen name="Organic" component={OrganicNav} />
              {Object.values(routes).map(route => (
                <Stack.Screen
                  key={route.name}
                  name={route.name}
                  component={route.component}
                  options={{headerShown: route.headerShown || false}}
                />
              ))}
            </Stack.Navigator>
          </NavigationContainer>
        </View>
      </SafeAreaView>
      {(netWrokChecked || globalStore.isWeb) && (
        <DialogLoading isVisible={globalLoading} />
      )}
      {renderToast}
      {versionModal.renderModal}
    </SafeAreaProvider>
  );
}

export default App;
