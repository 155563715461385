import {convertImgToObscureUrl} from '@utils';
import React, {useState} from 'react';
import {DimensionValue, ImageResizeMode, View} from 'react-native';
import {
  Image,
  ImageURISource,
  StyleSheet,
  ImageRequireSource,
} from 'react-native';
import {ImageUrlType} from './index.type';
import theme from '@style';
// export type LazyImageProps = ImageProps;
export interface LazyImageProps {
  // 图片宽度
  width?: DimensionValue;
  // 图片高度
  height?: DimensionValue;
  // 图片url，如果是一个静态图片就直接用source
  imageUrl: ImageUrlType;
  // 圆角
  radius?: number;
  resizeMode?: ImageResizeMode;
  // 占位背景色
  occupancy?: string;
}

function isNetImage(imageUrl: ImageUrlType): imageUrl is string {
  return typeof imageUrl === 'string' && imageUrl.startsWith('http');
}

const LazyImage: React.FC<LazyImageProps> = props => {
  const {
    imageUrl,
    width,
    height,
    radius,
    resizeMode = 'stretch',
    occupancy,
  } = props;
  const [showBlur, setShowBlur] = useState<boolean>(true);
  const blurredImageUrl: string | null = isNetImage(imageUrl)
    ? convertImgToObscureUrl(imageUrl)
    : null;

  const source: ImageRequireSource | ImageURISource = isNetImage(imageUrl)
    ? {uri: imageUrl}
    : imageUrl;

  const innerStyle = StyleSheet.create({
    image: {
      width: width || 'auto',
      height: height || 'auto',
      borderRadius: radius != null ? radius : 0,
    },
    view: {
      width: width || 'auto',
      height: height || 'auto',
      borderRadius: radius != null ? radius : 0,
    },
  });
  return (
    <View
      style={[
        styles.view,
        innerStyle.view,
        {
          backgroundColor: occupancy || theme.backgroundColor.palegrey,
        },
      ]}>
      {blurredImageUrl && showBlur && (
        <Image style={[innerStyle.image]} source={{uri: blurredImageUrl}} />
      )}
      <Image
        style={[innerStyle.image, styles.realImageFloat]}
        resizeMode={resizeMode}
        source={source}
        onLoad={() => setShowBlur(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  view: {
    position: 'relative',
  },
  realImageFloat: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: 'transparent',
  },
});

export default LazyImage;
