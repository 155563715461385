import NavTitle from '@basicComponents/nav-title';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import theme from '@style';
import LazyImage from '@basicComponents/image';
import {goTo, navigateTo, toPriceStr} from '@/utils';
import Text from '@basicComponents/text';
import globalStore from '@/services/global.state';
import i18n from '@/i18n';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {NavTitleProps} from '@basicComponents/nav-title/nav-title';
import WalletWeb from './wallet-web';
import Wallet from './wallet';
import {Subject, takeUntil} from 'rxjs';
import config from '@/utils/env.config';

/** 这个组件的leftNode会显示在客服按钮的右侧, rightNode会显示在钱包的左侧 */
const DetailNavTitle = (
  props: NavTitleProps & {
    hideServer?: boolean;
    hideAmount?: boolean;
    serverRight?: boolean;
  },
) => {
  const {
    title,
    hideServer,
    serverRight = false,
    hideAmount,
    leftNode,
    rightNode,
    ...otherProps
  } = props;
  const [login, setLogin] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const distory = new Subject<boolean>();
  useEffect(() => {
    globalStore.tokenSubject.pipe(takeUntil(distory)).subscribe(token => {
      setLogin(!!token);
    });
    globalStore.amountChanged.pipe(takeUntil(distory)).subscribe(res => {
      setAmount(res.current);
    });
    return () => {
      distory.next(true);
      distory.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderService = (
    <TouchableOpacity onPress={() => navigateTo(config.customerServerUrl)}>
      <LazyImage
        occupancy={'transparent'}
        width={theme.iconSize.l}
        height={theme.iconSize.l}
        imageUrl={require('@components/assets/icons/headphone.webp')}
      />
    </TouchableOpacity>
  );
  return (
    <NavTitle
      leftNode={
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          {!hideServer && !serverRight && renderService}
          {leftNode && (
            <View
              style={[hideServer || serverRight ? null : theme.margin.leftl]}>
              {leftNode}
            </View>
          )}
        </View>
      }
      rightNode={
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          {rightNode && (
            <View
              style={[
                (hideAmount || !login) && !serverRight
                  ? null
                  : theme.margin.rightl,
              ]}>
              {rightNode}
            </View>
          )}
          {serverRight && (
            <View style={[hideAmount ? null : theme.margin.rightl]}>
              {renderService}
            </View>
          )}
          {!hideAmount && (
            <View style={[theme.position.rel]}>
              <View
                style={[
                  theme.flex.row,
                  theme.flex.centerByCol,
                  theme.position.abs,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    right: 28,
                    top: globalStore.isWeb ? 18 : 16,
                  },
                ]}>
                <View
                  style={[
                    theme.flex.col,
                    theme.flex.alignEnd,
                    theme.margin.rightxxs,
                  ]}>
                  <Text
                    fontSize={theme.fontSize.xs}
                    style={[theme.font.second]}>
                    Balance
                  </Text>
                  <Text fontFamily="fontDin" style={[theme.font.second]}>
                    {toPriceStr(amount, {
                      currency: globalStore.currency,
                      needToken: true,
                    })}
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={() => {
                  globalStore.token
                    ? goTo('WebView', {
                        path: '/recharge',
                        header: true,
                      })
                    : goTo('Login');
                }}>
                {globalStore.isWeb ? (
                  <WalletWeb
                    style={[
                      {
                        marginTop: -theme.paddingSize.l,
                      },
                    ]}
                  />
                ) : (
                  <Wallet
                    style={[
                      {
                        marginTop: -theme.paddingSize.l,
                      },
                    ]}
                  />
                )}
              </TouchableOpacity>
            </View>
          )}
        </View>
      }
      title={title || i18n.t('loading')}
      {...otherProps}
    />
  );
};

export default DetailNavTitle;
