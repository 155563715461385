import {CurrencyMap} from '@/services/currency';
import globalStore from '@/services/global.state';

export function convertImgToObscureUrl(url: string) {
  const lastIndex = url.lastIndexOf('?');
  //
  const obscureParam =
    'x-oss-process=image/resize,m_fill,w_100,limit_0/blur,r_10,s_10/quality,q_10';
  if (lastIndex !== -1 && lastIndex !== url.length - 1) {
    return `${url}&${obscureParam}`;
  }
  return `${url}?${obscureParam}`;
}

interface ToPriceOptions {
  thousands?: boolean;
  spacing?: boolean;
  fixed?: number;
  currency?: string;
  needToken?: boolean;
}

export function toPriceStr(
  price?: number,
  {
    thousands = false,
    spacing = false,
    fixed = 2,
    currency = globalStore.currency,
    needToken = false,
  }: ToPriceOptions = {
    thousands: false,
    spacing: false,
    fixed: 2,
    currency: globalStore.currency,
    needToken: false,
  },
) {
  if (needToken && !globalStore.token) {
    return '-';
  }
  const space = spacing ? ' ' : '';
  if (!price) {
    return `${currency}${space}${(0).toFixed(fixed)}`;
  }
  const priceStr = price.toFixed(fixed);
  if (thousands) {
    if (currency === CurrencyMap.INR) {
      return `${currency}${space}${price.toLocaleString('en-IN', {
        minimumFractionDigits: fixed,
      })}`;
    }
    return `${currency}${space}${price.toLocaleString('en-US')}`;
  }
  return `${currency}${space}${priceStr}`;
}
